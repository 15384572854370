import React from "react";
import { setChannel } from "../redux/actions";
import { ContentStream } from "./ContentStream";
import { HlMapAppState } from "../redux/reducers";
import { showContentList, showCreateChannel } from "../redux/actions";
import { useSelector, connect } from "react-redux";
import { Content } from "../models/Content";
import { ChannelRef } from "../models/Channel";
import CSS from "csstype";
import "./ContentList.css";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { User } from '../models/User';
interface ContentListProps {
  onToggleList: (show: boolean) => any;
  onCreateChannel: () => any;
  style?: CSS.Properties;
  content: Content[];
  channel: ChannelRef | undefined;
  currentLocation: Coordinates;
  setChannel: typeof setChannel;
  user?: User;
}

export const _ContentList = (props: ContentListProps) => {
  const showContentList = useSelector(
    (state: HlMapAppState) => state.list.show
  );
  const isMobile = useMediaQuery(useTheme().breakpoints.down("sm"));

  return (
    <div
      className={`${isMobile ? "list_mobile" : "list_desktop"} ${
        showContentList ? "open" : "closed"
      }`}
      style={{ ...props.style }}
    >
      <div
        style={{
          minWidth: `${isMobile ? "100vw" : "30vw"}`,
          height: "100%",
          display: "flex",
          flexDirection: "column"
        }}
      >
        <div style={{ flex: 1 }}>
          <ContentStream user={props.user} content={props.content} channel={props.channel} />
        </div>
        {/* <Input
          id="test"
          value={message}
          onChange={event => setMessage(event.target.value)}
          onKeyDown={e => {
            if (e.keyCode === 13) {
              e.preventDefault();
              Data.postContent(
                props.currentLocation,
                [props.channel?.ref.id || ""],
                message
              ).then(() => setMessage(""));
            }
          }}
        /> */}
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setChannel: (channel?: ChannelRef) => dispatch(setChannel(channel)),
    onToggleList: (show: boolean) => dispatch(showContentList(show)),
    onCreateChannel: () => dispatch(showCreateChannel(true))
  };
};

const mapStateToProps = (state: HlMapAppState) => {
  return {
    content: state.channel.content,
    channel: state.channel.channel,
    currentLocation: state.location.coordinates,
    user: state.auth.user
  };
};

export const ContentList = connect(
  mapStateToProps,
  mapDispatchToProps
)(_ContentList);
