import React from "react";
import Button from "@material-ui/core/Button";
import { UserService } from "../services/UserService";
import Logo from "../assets/chick.svg";
import { Formik } from "formik";
import styles from "./UserRegister.module.css";
import { IconButton, TextField } from "@material-ui/core";
import { Close } from "@material-ui/icons";
export function UserRegister(props: {
  onClose?: () => void;
  onBack: () => void;
}) {
  const [formErrors, setFormErrors] = React.useState<{
    general?: string;
    displayName?: string;
    email?: string;
    password?: string;
  }>({
    general: undefined,
    displayName: undefined,
    email: undefined,
    password: undefined,
  });

  const onRegister = async (
    displayName: string,
    email: string,
    password: string
  ) => {
    setFormErrors({ ...formErrors, general: undefined });
    return UserService.registerUser(displayName, email, password)
      .then((result) => {
        console.log("User successfully created and logged in", result);
        props.onClose?.();
      })
      .catch((e?: { code: string; message: string }) => {
        switch (e ? e.code : "") {
          case "auth/email-already-in-use":
            setFormErrors({
              ...formErrors,
              email: "Email address already in use",
            });
            break;
          case "auth/invalid-email":
            setFormErrors({ ...formErrors, email: "Invalid email address" });
            break;
          case "auth/operation-not-allowed":
            setFormErrors({
              ...formErrors,
              general: "Something went wrong. Please try again.",
            });
            break;
          case "auth/weak-password":
            setFormErrors({ ...formErrors, password: "Password to weak" });
            break;
          default:
            setFormErrors({
              ...formErrors,
              general: "Systems error. Please try again later.",
            });
            break;
        }
      });
  };

  return (
    <div className={styles.outerContainer}>
      {props.onClose ? (
        <div className={styles.closeButton}>
          <IconButton aria-label="close" onClick={props.onClose}>
            <Close />
          </IconButton>
        </div>
      ) : null}
      <div className={styles.innerContainer}>
        <img className={styles.logo} alt="logo" src={Logo} />
        <h2>Register now</h2>
        <div className={styles.description}>
          for more hard love on the planet
        </div>
        <Formik
          initialValues={{ displayName: "", email: "", password: "" }}
          validate={(values) => {
            const errors: any = {};
            if (!values.email) {
              errors.email = "Required";
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = "Invalid email address";
            }

            if (!values.password) {
              errors.password = "Required";
            } else if (values.password.length < 8) {
              errors.password = "Minimum password length is 8";
            }

            if (!values.displayName) {
              errors.displayName = "Required";
            } else if (values.displayName.length < 3) {
              errors.displayName = "Minimum display name length is 3";
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            onRegister(
              values.displayName,
              values.email,
              values.password
            ).then(() => setSubmitting(false));
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit} className={styles.registerForm}>
              <TextField
                color="secondary"
                style={{ alignSelf: "stretch",  marginBottom: 16 }}
                error={!!errors.displayName && !!touched.displayName}
                helperText={errors.displayName}
                onBlur={handleBlur}
                required
                name="displayName"
                type="text"
                label="Display Name"
                placeholder="hard@love.com"
                variant="outlined"
                value={values.displayName}
                onChange={handleChange}
              />
              <TextField
                color="secondary"
                style={{ alignSelf: "stretch", marginBottom: 16 }}
                error={!!errors.email && !!touched.email}
                helperText={errors.email}
                required
                name="email"
                type="email"
                label="Email"
                placeholder="hard@love.com"
                variant="outlined"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <TextField
                color="secondary"
                style={{ alignSelf: "stretch", marginBottom: 16 }}
                error={!!errors.password && !!touched.password}
                helperText={errors.password}
                required
                name="password"
                type="password"
                label="Password"
                placeholder="********"
                variant="outlined"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <div
                style={{ color: "gray", fontSize: 10, alignSelf: "flex-end" }}
              >
                * Required fields
              </div>
              <div
                className={styles.errorContainer}
                hidden={!formErrors.general}
              >
                {formErrors.general}
              </div>
              <div className={styles.buttonContainer}>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={() => {
                    props.onBack();
                  }}
                >
                  Go Back
                </Button>
                <Button
                  disabled={isSubmitting}
                  variant="contained"
                  color="primary"
                  fullWidth
                  type="submit"
                >
                  Register
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
}
