import React from "react";
import Donate from "../assets/donate.gif";
import {
  Chip,
  Avatar,
  Paper,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Input,
  Button,
  TextField,
} from "@material-ui/core";
import "./Loyalty.css";
import Chick from "../assets/chick.svg";
export const Loyalty = (props: { style?: React.CSSProperties }) => {
  const [amount, setAmount] = React.useState(5);
  return (
    <Paper className="outerContainer" style={props.style}>
      <div className="donateContainer">
        <div className="donateText">
          <h1 className="donateHeader">Donate, or hate!</h1>
          <p>
            Help us to spread some hard love! We will use your donations for
            drinks and drugs to keep this service up running.
          </p>
          <p>Thanks, your HarteLiebe-Crew</p>
        </div>
      </div>
      <img className="donateTeaser" src={Donate} />
      <FormControl className="moneyButtons">
        <div className="leftDonateButtons">
          <RadioGroup
            aria-label="Amount"
            name="amount"
            value={amount}
            onChange={(_, value) => setAmount(parseInt(value))}
          >
            <FormControlLabel value={5} control={<Radio color="primary" />} label="5€" />
            <FormControlLabel value={15} control={<Radio color="primary"/>} label="15€" />
            <FormControlLabel value={25} control={<Radio color="primary"/>} label="25€" />
            <FormControlLabel value={50} control={<Radio color="primary"/>} label="50€" />
          </RadioGroup>
        </div>

        <div className="rightDonateButtons">
          <FormLabel component="legend">Amount</FormLabel>
          <TextField
            className="donateInput"
            onChange={(e) => setAmount(isNaN(parseInt(e.target.value)) ? 0 : parseInt(e.target.value))}
            inputProps={{ style: { fontSize: 24, textAlign: "center" } }}
            InputProps={{
              startAdornment: <span>€</span>,
              style: { fontSize: 24, textAlign: "center" },
            }}
            value={amount}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              window.open("https://paypal.me/harrrteliebe/" + amount, "_blank")
            }
          >
            Donate
          </Button>
        </div>
      </FormControl>
    </Paper>
  );
};
