import React from "react";
import { connect } from "react-redux";
import { HlMapAppState } from "../redux/reducers";
import {
  setChannel,
  showCreateChannel,
  showContentList,
  showAccount,
  showLoyalty,
} from "../redux/actions";
import { User } from "../models/User";
import { IconButton, AppBar } from "@material-ui/core";
import { ChannelRef } from "../models/Channel";
import { Menu, MenuOpen, Settings, Loyalty } from "@material-ui/icons";

import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { ChannelSelector } from "./ChannelSelector";
const mapDispatchToProps = (dispatch: any) => {
  return {
    onShowAccount: (show: boolean) => dispatch(showAccount(show)),
    onSetChannel: (channel?: ChannelRef) => dispatch(setChannel(channel)),
    onCreateChannel: () => dispatch(showCreateChannel(true)),
    onToggleList: (show: boolean) => dispatch(showContentList(show)),
    onShowLoyalty: (show: boolean) => dispatch(showLoyalty(show)),
  };
};

const mapStateToProps = (state: HlMapAppState) => {
  return {
    channel: state.channel.channel,
    showImageList: state.list.show,
    user: state.auth.user,
  };
};

interface AppHeaderProps {
  channel?: ChannelRef;
  user?: User;
  showImageList: boolean;
  onToggleList: typeof showContentList;
  onSetChannel: typeof setChannel;
  onCreateChannel: () => any;
  onShowAccount: typeof showAccount;
  onShowLoyalty: typeof showLoyalty;
}

const _AppHeader = (props: AppHeaderProps) => {
  const isMobile = useMediaQuery(useTheme().breakpoints.down("sm"));
  return (
    <>
      <AppBar position="static">
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          {!isMobile && (
            <IconButton
              style={{ color: "white" }}
              onClick={() => props.onToggleList(!props.showImageList)}
            >
              {props.showImageList ? <MenuOpen /> : <Menu />}
            </IconButton>
          )}
          <ChannelSelector style={{ flex: 1, height: 48 }} />
          {!isMobile && (
            <div>
              <IconButton
                style={{ alignSelf: "flex-end", color: "white" }}
                onClick={() => {
                  props.onShowLoyalty(true);
                }}
              >
                <Loyalty />
              </IconButton>
              <IconButton
                style={{ alignSelf: "flex-end", color: "white" }}
                onClick={() => {
                  props.onShowAccount(true);
                }}
              >
                <Settings />
              </IconButton>
            </div>
          )}
        </div>
      </AppBar>
    </>
  );
};

export const AppHeader = connect(
  mapStateToProps,
  mapDispatchToProps
)(_AppHeader);
