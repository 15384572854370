import React from "react";
import { CloudUpload } from "@material-ui/icons";
import { Chip, Button, Select, MenuItem } from "@material-ui/core";
import { ChannelRef } from "../models/Channel";
import "./ImageResult.css";
import { UploadContentData } from "../models/Content";
import { DynamicContentLayers } from "./DynamicContentLayers";

export function ImageResult(props: {
  content?: UploadContentData;
  uploadContentData?: (
    content: UploadContentData,
    shareWith: string[]
  ) => Promise<any>;
  shareOn?: ChannelRef[];
  style: React.CSSProperties;
  channels: ChannelRef[];
  isLoading: (loading: boolean, message?: string) => void;
}) {
  const [shareOnChannels, setShareOnChannels] = React.useState<ChannelRef[]>(
    props.shareOn || []
  );

  return (
    <div className="ir_outer_container" style={props.style}>
      <div className="ir_content_container">
        <img alt="Result" src={props.content?.image} className="ir_image" />
        {props.content?.dynamicLayers && (
          <DynamicContentLayers layers={props.content.dynamicLayers} />
        )}
      </div>
      <div className="ir_select_container">
        <Select
          className="ir_select"
          multiple
          disableUnderline={true}
          value={shareOnChannels}
          renderValue={(selected) => {
            const refs = selected as ChannelRef[];
            return (
              <div className="ir_chip_container">
                {refs.map((value) => (
                  <Chip key={value.ref.id} label={"#" + value.data.name} />
                ))}
              </div>
            );
          }}
          onChange={(e) => {
            const refs = e.target.value as ChannelRef[];
            if (refs.length > 0) {
              setShareOnChannels(refs);
            }
          }}
        >
          {props.channels.map((c) => {
            return (
              <MenuItem
                selected={shareOnChannels.some((sc) => sc.ref.id === c.ref.id)}
                key={c.ref.id}
                value={c as any}
              >
                #{c.data.name}
              </MenuItem>
            );
          })}
        </Select>
        <Button
          className="ir_upload_button"
          variant="contained"
          color="primary"
          endIcon={<CloudUpload />}
          disabled={!shareOnChannels.length}
          onClick={() => {
            if (props.content) {
              props.isLoading(true, "Uploading image...");
              props
                .uploadContentData?.(
                  props.content,
                  shareOnChannels.map((channel) => channel.data.ref.id)
                )
                .finally(() => {
                  props.isLoading(false);
                });
            }
          }}
        >
          Share
        </Button>
      </div>
    </div>
  );
}
