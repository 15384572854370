import React from "react";
import { Content, ContentLocation } from "../models/Content";
import { ChannelService } from "../services/ChannelService";
import { CircularProgress } from "@material-ui/core";
import { apiUrls } from "../services/URLService";
import styles from "./ContentGalleryItem.module.css";
import { DynamicContentLayers } from "./DynamicContentLayers";

export const ContentGalleryItem = (props: {
  content: Content | ContentLocation;
  style?: React.CSSProperties;
}) => {
  const [loading, setLoading] = React.useState(true);
  const [imageSrc, setImageSrc] = React.useState<string | undefined>();
  const [loadedContent, setLoadedContent] = React.useState<
    Content | null | undefined
  >();

  const onImageLoaded = () => {
    setLoading(false);
  };

  React.useEffect(() => {
    if ("data" in props.content) {
      setLoadedContent(props.content as Content);
    } else {
      ChannelService.getContent(props.content.ref).then((r) => {
        setLoadedContent(r);
      });
    }
  }, [props.content]);

  React.useEffect(() => {
    if (loadedContent?.data?.image) {
      setImageSrc(apiUrls.get.image(loadedContent.data.image.id));
    } else {
      setLoading(!loadedContent);
    }
  }, [loadedContent]);

  return (
    <div className={styles.outerContainer} style={props.style}>
      {loading && (
        <div className={styles.loading}>
          <CircularProgress style={{ height: "80px", width: "80px" }} />
        </div>
      )}
      <div className={styles.innerContainer}>
        {imageSrc && (
          <img
            alt={props.content.ref.id}
            className={styles.image}
            onLoad={onImageLoaded}
            src={imageSrc}
          ></img>
        )}
        {loadedContent?.data?.text && (
          <div className={styles.text}>{loadedContent?.data.text}</div>
        )}
        {loadedContent?.data.dynamicLayers && (
          <DynamicContentLayers layers={loadedContent.data.dynamicLayers} />
        )}
      </div>
    </div>
  );
};
