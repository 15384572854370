import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

interface AlertDialogProps {
  buttonYesTitle?: string;
  buttonNoTitle?: string;
  title?: string;
  description?: string;
  error?: string;
  open: boolean;
  onClose: () => any;
  onAnswerSelected: (selectedAnswer: boolean) => void;
}

export default function AlertDialog(props: AlertDialogProps) {
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {props.description}
        </DialogContentText>
        <DialogContentText id="alert-dialog-error" style={{ color: "red" }}>
          {props.error}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.onAnswerSelected(false)} color="secondary">
          {props.buttonNoTitle ? props.buttonNoTitle : "No"}
        </Button>
        <Button onClick={() => props.onAnswerSelected(true)} color="primary">
          {props.buttonYesTitle ? props.buttonYesTitle : "Yes"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
