import { UserSearchResult } from "./../models/User";
import { ChannelSearchResult } from "./../models/Channel";
import { HLFirebase } from "./HLFirebase";

class _SearchService {
  async user(search?: string) {
    if (!search || search.length < 3) {
      return [];
    }
    const searchRequest = {
      request: {
        index: "users",
        type: "users",
        q: `*${search}*`
      },
      response: null
    };

    const searchRequestDoc = await HLFirebase.app
      .firestore()
      .collection("search")
      .add(searchRequest);

    const searchPromise = new Promise<UserSearchResult[]>((resolve, reject) => {
      const timeout = setTimeout(() => reject("Timeout"), 2000);
      searchRequestDoc.onSnapshot(result => {
        if (!result.exists || !result.data()) {
          reject("No data");
        }
        if (result.data()?.response) {
          clearTimeout(timeout);
          const data = Object.values(
            result.data()!.response.hits.hits as any[]
          ).map(object => {
            return {
              score: object._score,
              id: object._id,
              source: object._source
            };
          });
          resolve(data);
        }
      });
    });
    const searchResults = await searchPromise;
    return searchResults;
  }

  async channels(search?: string, exact = false) {
    // q: `name:*${search}* AND visibility:public`
    const query = exact ? `name:${search}` : `name:*${search}*`;

    const searchRequest = {
      request: {
        index: "channels",
        type: "channels",
        q: query
      },
      response: null
    };
    const searchRequestDoc = await HLFirebase.app
      .firestore()
      .collection("search")
      .add(searchRequest);
    const searchPromise = new Promise<ChannelSearchResult[]>(
      (resolve, reject) => {
        const timeout = setTimeout(() => reject("Timeout"), 2000);
        searchRequestDoc.onSnapshot(result => {
          if (!result.exists || !result.data()) {
            reject("No data");
          }
          if (result.data()?.response) {
            clearTimeout(timeout);
            const data = Object.values(
              result.data()!.response.hits.hits as any[]
            ).map(object => {
              return {
                score: object._score,
                id: object._id,
                source: object._source
              };
            });
            resolve(data);
          }
        });
      }
    );
    const searchResults = await searchPromise;
    return searchResults;
  }
}
export const SearchService = new _SearchService();
