const dev = {
  apiKey: "AIzaSyAOhvUHchH3_gw0kxsY2G3sn2ujkK0yU8s",
  authDomain: "harte-liebe-dev.firebaseapp.com",
  databaseURL: "https://harte-liebe-dev.firebaseio.com",
  projectId: "harte-liebe-dev",
  storageBucket: "harte-liebe-dev.appspot.com",
  messagingSenderId: "330803907510",
  appId: "1:330803907510:web:6c79f64c0eb62a44d14f17",
};

const prod = {
  apiKey: "AIzaSyA8iCV_g4IJP-P_DKiCnl9EjipbnH_cUr0",
  authDomain: "harte-liebe-prod.firebaseapp.com",
  databaseURL: "https://harte-liebe-prod.firebaseio.com",
  projectId: "harte-liebe-prod",
  storageBucket: "harte-liebe-prod.appspot.com",
  messagingSenderId: "497664433183",
  appId: "1:497664433183:web:1409d3579844ef66a4092a",
  measurementId: "G-7X9WCL0VJG",
};
export const firebaseCredentials = () => {
  if (process.env.REACT_APP_STAGE === "prod") {
    return prod;
  } else {
    return dev;
  }
};

export const firebaseFunctionsUrl = () => {
    if (process.env.REACT_APP_STAGE === "prod") {
        return "https://worldwidetakeover.harteliebe.com";
      } else {
        return "https://harte-liebe-dev-map.firebaseapp.com";
      }
};
