import React from "react";
// import { ContentLocationInfoWindow } from '../components/ImageInfoWindow';
import { Image } from "../models/Image";
import { ContentLocation } from "../models/Content";
import {
  LoadScript,
  GoogleMap,
  Marker,
  MarkerClusterer,
} from "@react-google-maps/api";

import { HUD } from "./HUD";
import { GeoFire } from "../services/GeoFire";
import MyLocation from "@material-ui/icons/MyLocation";
import MapControl from "./MapControl";
import Button from "@material-ui/core/Button";
import { FilterSetting } from "../models/Settings";
import {
  setSearchPlace,
  setSearchLocation,
  setSearchRadius,
  showLocationDetail,
  scrollToContentLocation,
  panToContentLocation,
  zoomToContentLocation,
  selectContentLocation,
  setChannel,
} from "../redux/actions";
import { connect } from "react-redux";
import { HlMapAppState } from "../redux/reducers";
import { ChannelRef } from "../models/Channel";
import fistEmoji from "../assets/fist_emoji.svg";
import heartEmoji from "../assets/heart_emoji.svg";
import chicken from "../assets/chicken.svg";
// declare const google: any;
interface MapState {
  zoom: number;
  mode: MapMode;
  openInfoWindows: any[];
  selectedImages: Image[];
  mapLoaded: boolean;
}

export enum MapMode {
  Location = "LOCATION",
  Search = "SEARCH",
  Region = "REGION",
}

interface MapProps {
  onShowContentLocationDetail: (enabled: boolean) => any;
  onScrollToContentLocation: (marker: ContentLocation) => any;
  onPanToContentLocation: (marker?: ContentLocation) => any;
  onZoomToContentLocation: (marker?: ContentLocation, zoom?: number) => any;
  onSelectContentLocation: (marker: ContentLocation) => any;
  onSetSearchRadius: (radius: number) => any;
  onSetSearchLocation: (location: Coordinates) => any;
  onSetSearchPlace: (place: google.maps.places.PlaceResult) => any;
  locations: ContentLocation[];
  filter: FilterSetting[];
  selectedContentLocation: ContentLocation | undefined;
  panToContentLocation: ContentLocation | undefined;
  zoomToContentLocation: ContentLocation | undefined;
  coordinates: Coordinates;
  channel?: ChannelRef;
  zoom: number;
  style?: React.CSSProperties;
}

const gMapsApiKey = "AIzaSyDsLI1WEOaaBck5JmN0nzlSbtDq9NeXdh0";

class Map extends React.Component<MapProps, MapState> {
  update: any;
  sharedMarkers: any[] = [];
  mapMoved: boolean = false;
  largestScannedBounds: google.maps.LatLngBounds | null | undefined;
  libraries = ["geometry"]; //['geometry', 'drawing', 'places'];
  map: google.maps.Map | undefined;
  defaultLocation: any;
  searchRadiusCircle?: google.maps.Circle;

  // placesService?: google.maps.places.PlacesService
  geoCoder?: google.maps.Geocoder;

  constructor(props: MapProps) {
    super(props);
    this.state = {
      zoom: 7,
      mode: MapMode.Location,
      selectedImages: [],
      openInfoWindows: [],
      mapLoaded: false,
    };
    this.defaultLocation = {
      lat: this.props.coordinates.latitude,
      lng: this.props.coordinates.longitude,
    };
  }

  onClusterClick = (event: any) => {
    if (this.state.mode !== MapMode.Region) {
      this.setState({ mode: MapMode.Region });
    }
    const locations = this.props.locations.filter((location) =>
      event.markers.find((em: any) => em.title === location.ref.id)
    );
    if (locations.length > 0) {
      const initialPosition = locations[0].location;
      let shouldSelect = true;
      for (const l of locations) {
        if (!l.location.isEqual(initialPosition)) {
          shouldSelect = false;
          break;
        }
      }
      if (shouldSelect) {
        this.selectMarkers(locations);
      }
    }
  };

  onBoundsChanged = () => {
    if (!this.map) {
      return;
    }

    if (
      !this.largestScannedBounds ||
      !this.largestScannedBounds!.contains(
        this.map!.getBounds()!.getNorthEast()
      ) ||
      !this.largestScannedBounds!.contains(
        this.map!.getBounds()!.getSouthWest()
      )
    ) {
      this.largestScannedBounds = this.map!.getBounds();

      if (this.update) {
        clearTimeout(this.update);
      }
      this.update = setTimeout(this.queryMarkers, 1000);
    }
  };

  updateMarkers = () => {};

  queryMarkers = () => {
    clearTimeout(this.update);
    if (!this.map || !this.props.channel) {
      return;
    }
    if (this.map.getCenter()) {
      let radius = this.viewRadius();

      const searchLocation = {
        latitude: this.map.getCenter().lat(),
        longitude: this.map.getCenter().lng(),
      } as any;

      this.props.onSetSearchRadius(radius);
      this.props.onSetSearchLocation(searchLocation);

      if (this.searchRadiusCircle) {
        this.searchRadiusCircle.setMap(null);
      }

      // this.searchRadiusCircle = new google.maps.Circle({
      //   strokeColor: "#FF0000",
      //   strokeOpacity: 0.8,
      //   strokeWeight: 2,
      //   fillColor: "#FF0000",
      //   fillOpacity: 0.15,
      //   map: this.map,
      //   center: this.map.getCenter(),
      //   radius: radius * 1000
      // });

      // if (this.geoCoder) {
      //   this.geoCoder.geocode(
      //     { location: this.map.getCenter() },
      //     (results, status) => {
      //       if (status === "OK") {
      //         let zip: any = results.find(r =>
      //           r.types.some(t => t === "postal_code")
      //         );
      //         if (!zip) {
      //           zip = results[0];
      //         }
      //         this.props.onSetSearchPlace(zip);
      //       }
      //     }
      //   );
      // }
      GeoFire.updateGeoQueries(
        { lat: this.map.getCenter().lat(), lng: this.map.getCenter().lng() },
        radius,
        this.props.channel.ref.id
      );
    }
  };

  viewRadius = () => {
    if (!this.map || !this.map) {
      return 0;
    }
    var bounds = this.map!.getBounds();
    var center = this.map!.getCenter();
    if (bounds && center) {
      var ne = bounds.getNorthEast();
      // Calculate radius (in meters).
      return (
        google.maps.geometry.spherical.computeDistanceBetween(center, ne) / 1000
      );
    }
    return 1000;
  };

  selectMarkers = (locations: ContentLocation[]) => {
    if (!this.map || !locations.length) {
      return;
    }
    if (this.state.mode !== MapMode.Region) {
      this.setState({ mode: MapMode.Region });
    }
    if (
      locations.length === 1 &&
      locations[0].ref.id === this.props.selectedContentLocation?.ref.id
    ) {
      this.props.onShowContentLocationDetail(true);
      return;
    }
    this.props.onSelectContentLocation(locations[0]);
    this.props.onShowContentLocationDetail(true);
  };

  componentDidUpdate(prevProps: MapProps) {
    if (!this.map) {
      return;
    }

    if (prevProps.channel !== this.props.channel) {
      this.largestScannedBounds = undefined;
      this.queryMarkers();
    }
    const centerToDeviceDistance = google.maps.geometry.spherical.computeDistanceBetween(
      this.map.getCenter(),
      new google.maps.LatLng(
        this.props.coordinates.latitude,
        this.props.coordinates.longitude
      )
    );
    if (this.props.panToContentLocation) {
      const marker = this.props.panToContentLocation;
      this.map.panTo({
        lat: marker.location.latitude,
        lng: marker.location.longitude,
      });
      this.setState({ mode: MapMode.Region });
      this.props.onPanToContentLocation();
    } else if (this.props.zoomToContentLocation) {
      const marker = this.props.zoomToContentLocation;
      this.map.setZoom(this.props.zoom);
      this.map.panTo({
        lat: marker.location.latitude,
        lng: marker.location.longitude,
      });
      this.setState({ mode: MapMode.Region });
      this.props.onZoomToContentLocation();
    } else if (
      this.state.mode === MapMode.Location &&
      centerToDeviceDistance > 200 &&
      this.props.coordinates
    ) {
      this.map.panTo({
        lat: this.props.coordinates.latitude,
        lng: this.props.coordinates.longitude,
      });
    }
  }

  onLoad = (mapInstance: any) => {
    this.map = mapInstance;
    // this.placesService = new google.maps.places.placesService(this.map);
    this.geoCoder = new google.maps.Geocoder();
  };

  centerOnUser = () => {
    if (this.map && this.props && this.props.coordinates) {
      this.setState({ mode: MapMode.Location });
      this.map.panTo({
        lat: this.props.coordinates.latitude,
        lng: this.props.coordinates.longitude,
      });
    }
  };

  onZoomChanged = () => {
    if (!this.map) {
      return;
    }
    // this.props.onZoomToContentLocation(undefined, this.map.getZoom());
  };

  onDrag = () => {
    if (this.state.mode !== MapMode.Region) {
      this.setState({ mode: MapMode.Region });
    }
  };

  render() {
    return (
      <div
        style={{
          height: "100%",
          width: "100%",
          position: "relative",
          ...this.props.style,
        }}
      >
        <LoadScript
          id="script-loader"
          googleMapsApiKey={gMapsApiKey}
          libraries={this.libraries}
        >
          <GoogleMap
            onLoad={this.onLoad}
            options={{
              fullscreenControl: false,
              mapTypeControl: false,
              mapTypeControlOptions: {
                position: this.map
                  ? google.maps.ControlPosition.BOTTOM_CENTER
                  : 0,
              },
              clickableIcons: false,
            }}
            mapContainerStyle={{ height: "100%", width: "100%" }}
            zoom={this.props.zoom}
            center={this.defaultLocation}
            onBoundsChanged={this.onBoundsChanged}
            onDrag={this.onDrag}
            onZoomChanged={this.onZoomChanged}
          >
            <MarkerClusterer
              averageCenter
              enableRetinaIcons
              options={{
                styles: [
                  {
                    url: chicken,
                    width: 56,
                    height: 56,
                  },
                  {
                    url: fistEmoji,
                    width: 56,
                    height: 56,
                  },
                  {
                    url: heartEmoji,
                    width: 56,
                    height: 56,
                  },
                ],
              }}
              calculator={(markers, num) => {
                const markerContents = this.props.locations.filter((l) =>
                  markers.some((m) => m.getTitle() === l.ref.id)
                );
                let styleIndex = 1;
                if (
                  markerContents.filter((c) => c.icon === "love").length <
                  markerContents.filter((c) => c.icon === "hard").length
                ) {
                  styleIndex = 2;
                } else if (
                  markerContents.filter((c) => c.icon === "love").length >
                  markerContents.filter((c) => c.icon === "hard").length
                ) {
                  styleIndex = 3;
                }
                return {
                  text: markers.length.toString(),
                  title: num.toString(),
                  index: styleIndex,
                };
              }}
              gridSize={60}
              onClick={this.onClusterClick}
            >
              {(clusterer) => {
                return this.props.locations
                  .filter(
                    (location) =>
                      !this.props.filter.some(
                        (filter) => filter.type === (location.type as any)
                      )
                  )
                  .map((marker) => (
                    <Marker
                      title={marker.ref.id}
                      clusterer={clusterer}
                      onClick={() => this.selectMarkers([marker])}
                      key={marker.ref.id}
                      icon={{
                        url:
                          marker.icon === "hard"
                            ? fistEmoji
                            : marker.icon === "love"
                            ? heartEmoji
                            : chicken,
                        scaledSize: new google.maps.Size(40, 40),
                      }}
                      position={{
                        lat: marker.location.latitude,
                        lng: marker.location.longitude,
                      }}
                    />
                  ));
              }}
            </MarkerClusterer>
            {this.map && (
              <MapControl position={google.maps.ControlPosition.RIGHT_BOTTOM}>
                <Button
                  color="secondary"
                  type="button"
                  onClick={this.centerOnUser}
                  style={{
                    backgroundColor: "white",
                    position: "absolute",
                    right: 8,
                    bottom: 0,
                    maxWidth: 42,
                    maxHeight: 42,
                    minWidth: 42,
                    minHeight: 42,
                  }}
                >
                  <MyLocation />
                </Button>
              </MapControl>
            )}
          </GoogleMap>
        </LoadScript>
        <HUD />
      </div>
    );
  }
}

const mapStateToProps = (state: HlMapAppState) => {
  return {
    // dispatching plain actions
    locations: state.channel.locations,
    selectedContentLocation: state.dialog.selectContentLocation,
    panToContentLocation: state.map.panToContentLocation,
    zoomToContentLocation: state.map.zoomToContentLocation,
    coordinates: state.location.coordinates,
    zoom: state.map.zoom,
    searchRadius: state.map.searchRadius,
    filter: state.settings.mapContentFilter,
    channel: state.channel.channel,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    // dispatching plain actions
    onShowContentLocationDetail: (enabled: boolean) =>
      dispatch(showLocationDetail(enabled)),
    onScrollToContentLocation: (marker: ContentLocation) =>
      dispatch(scrollToContentLocation(marker)),
    onSelectContentLocation: (marker: ContentLocation) =>
      dispatch(selectContentLocation(marker)),
    onPanToContentLocation: (marker?: ContentLocation) =>
      dispatch(panToContentLocation(marker)),
    onZoomToContentLocation: (marker?: ContentLocation, zoom?: number) =>
      dispatch(zoomToContentLocation(marker, zoom)),
    onSetSearchRadius: (radius: number) => dispatch(setSearchRadius(radius)),
    onSetSearchLocation: (location: Coordinates) =>
      dispatch(setSearchLocation(location)),
    onSetSearchPlace: (place: google.maps.places.PlaceResult) =>
      dispatch(setSearchPlace(place)),
    onSetSelectedChannel: (channel?: ChannelRef) =>
      dispatch(setChannel(channel)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Map);
