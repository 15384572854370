import React from "react";
import { ContentElement } from "./ContentElement";
import { Content } from "../models/Content";
import {
  panToContentLocation,
  showContentDetail,
  selectContentLocation,
  zoomToContentLocation,
  setSelectedTab,
} from "../redux/actions";
import { CircularProgress } from "@material-ui/core";
import { HlMapAppState } from "../redux/reducers";
import { useDispatch, useSelector } from "react-redux";
import "react-virtualized/styles.css";
import {
  AutoSizer,
  List,
  ListRowRenderer,
  InfiniteLoader,
  IndexRange,
  Index,
} from "react-virtualized";
import { ChannelService } from "../services/ChannelService";
import { ChannelRef } from "../models/Channel";
import { User } from "../models/User";

export const ContentStream = (props: {
  content: Content[];
  channel?: ChannelRef;
  user?: User;
}) => {
  const zoom = useSelector((state: HlMapAppState) => state.map.zoom);
  // const [scrollParams, setScrollParams] = React.useState<OnScrollParams>();
  const dispatch = useDispatch();

  const [count, setCount] = React.useState(25);

  const [emojiAnchorEl, setEmojiAnchorEl] = React.useState(null);
  const [
    selectedContent,
    setSelectedContent,
  ] = React.useState<Content | null>();

  const showPicker = (event: any, content: Content) => {
    setSelectedContent(content);
    setEmojiAnchorEl(event.currentTarget);
  };

  const hidePicker = () => {
    setEmojiAnchorEl(null);
  };

  const iconType = (content: Content) => {
    return (content?.data?.reactions?.find((c) => c.reaction === "hard")
      ?.count || 0) ===
      (content?.data?.reactions?.find((c) => c.reaction === "love")?.count || 0)
      ? "hard"
      : "love";
  };

  const onContentLocationSelect = (content: Content) => {
    dispatch(showContentDetail(true));
    dispatch(
      selectContentLocation({
        ref: content.ref,
        icon: iconType(content),
        location: content.data.location,
        type: content.data.image ? "image" : "text",
      })
    );
  };

  const onContentLocationZoomIn = (content: Content) => {
    dispatch(
      zoomToContentLocation(
        {
          ref: content.ref,
          icon: iconType(content),
          location: content.data.location,
          type: content.data.image ? "image" : "text",
        },
        15
      )
    );
    dispatch(setSelectedTab(1));
  };

  const onContentLocationZoomOut = (content: Content) => {
    dispatch(
      zoomToContentLocation(
        {
          ref: content.ref,
          location: content.data.location,
          icon: iconType(content),
          type: content.data.image ? "image" : "text",
        },
        7
      )
    );
    dispatch(setSelectedTab(1));
  };

  const onCenterToContentLocation = (content: Content) => {
    dispatch(
      panToContentLocation({
        ref: content.ref,
        icon:
          content.data.reactions.filter((c) => c.reaction === "👊").length >
          content.data.reactions.length / 2
            ? "hard"
            : "love",
        location: content.data.location,
        type: content.data.image ? "image" : "text",
      })
    );
    dispatch(setSelectedTab(1));
  };

  //   React.useEffect(() => {
  //     if (scrollTo && listRef && listRef.current) {
  //       const index = props.content.findIndex(m => m.ref.id === scrollTo.ref.id);
  //       if (index !== -1) {
  //         const position = index * (listRef.current!.props.rowHeight as number);
  //         const visibleRows = listRef.current!.props.height / 160;
  //         const shouldScroll = props.content.length - visibleRows >= index;
  //         if (shouldScroll) {
  //           listRef.current!.scrollToPosition(position);
  //         }
  //       }
  //     }
  //   });

  React.useEffect(() => {
    if (props.channel) {
      return ChannelService.subscribeContent(props.channel.ref.id, count);
    }
  }, [props.channel, count]);

  const onReaction = (emoji: string, content?: Content) => {
    if (content || selectedContent) {
      ChannelService.reactToContent(
        emoji,
        content ? content : selectedContent!
      );
    }
  };

  const onContentDelete = (content: Content) => {
    if (content) {
      ChannelService.deleteContent(content);
    }
  };

  const rowRenderer: ListRowRenderer = (rowProps) => {
    // const content = (measureProps: any) => {
    if (!isRowLoaded({ index: rowProps.index })) {
      return (
        <div
          key="loading"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            ...rowProps.style,
          }}
        >
          <CircularProgress />
        </div>
      );
    } else {
      return (
        <ContentElement
          // ref={measureProps.registerChild}
          // onLoad={measureProps.measure}
          onSelect={onContentLocationSelect}
          onCenter={onCenterToContentLocation}
          onZoomIn={onContentLocationZoomIn}
          onZoomOut={onContentLocationZoomOut}
          onDelete={onContentDelete}
          onReaction={onReaction}
          onShowPicker={showPicker}
          user={props.user}
          key={props.content[rowProps.index].ref.id}
          content={props.content[rowProps.index]}
          style={rowProps.style}
        />
      );
    }
    // };

    // return (
    //   <CellMeasurer
    //     cache={_cache}
    //     key={rowProps.key}
    //     parent={rowProps.parent}
    //     rowIndex={rowProps.index}
    //     width={300}
    //   >
    //     {(measureProps) => content(measureProps)}
    //   </CellMeasurer>
    // );
  };

  const fetchContent = async (params: IndexRange) => {
    const increaseBy = 15;
    if (count < params.stopIndex + increaseBy) {
      setCount(params.stopIndex + increaseBy);
    }
  };

  const isRowLoaded = (params: Index) => {
    if (params.index < props.content.length) {
      return !!props.content[params.index];
    }
    return false;
  };

  return (
    <div id="streamContainer" style={{ width: "100%", height: "100%" }}>
      <>
        <InfiniteLoader
          isRowLoaded={isRowLoaded}
          loadMoreRows={fetchContent}
          rowCount={props.content.length + 1}
        >
          {({ onRowsRendered, registerChild }) => (
            <AutoSizer>
              {({ height, width }) => (
                <List
                  ref={registerChild}
                  rowCount={props.content.length + 1}
                  rowHeight={width * 0.5625 + 48}
                  height={height}
                  width={width}
                  rowRenderer={rowRenderer}
                  onRowsRendered={onRowsRendered}
                />
              )}
            </AutoSizer>
          )}
        </InfiniteLoader>
      </>
    </div>
  );
};
