import React, { FC } from "react";
import { GeolocatedProps, geolocated } from "react-geolocated";
import { HlMapAppState } from "../redux/reducers";
import {
  setCurrentLocation,
  enableLoctionServices,
  showLocationBanner,
} from "../redux/actions";
import { connect } from "react-redux";
import { isChrome, isFirefox, isSafari } from "react-device-detect";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import { useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";

const mapStateToProps = (state: HlMapAppState) => ({
  locationServicesEnabled: state.location.locationServicesEnabled,
  currentLocation: state.location.coordinates,
  showLocationBanner: state.location.showLocationBanner,
});

const mapDispatchToProps = (dispatch: any) => ({
  setCurrentLocation: (coords: Coordinates) =>
    dispatch(setCurrentLocation(coords)),
  enableLoctionServices: (enabled: boolean) =>
    dispatch(enableLoctionServices(enabled)),
  onShowLocationBanner: (show: boolean) => dispatch(showLocationBanner(show)),
});

interface LocationProps {
  setCurrentLocation: (coords: Coordinates) => any;
  enableLoctionServices: (enabled: boolean) => any;
  onShowLocationBanner: typeof showLocationBanner;
  showLocationBanner: boolean;
  currentLocation?: Coordinates;
  locationServicesEnabled?: boolean;
}

const _Location: FC<LocationProps> = (props) => {
  const innerRef = React.useRef<any>(null);
  const [error, setError] = React.useState<string | undefined>();
  const [dismissNotifications, setDismissNotifications] = React.useState(false);
  const [geoProps, setGeoProps] = React.useState<GeolocatedProps | null>(null);

  React.useEffect(() => {
    if (dismissNotifications && props.showLocationBanner) {
      setDismissNotifications(false);
      props.onShowLocationBanner(false);
    }
  }, [props.showLocationBanner, dismissNotifications]);

  React.useEffect(() => {
    localStorage.setItem(
      "locationAccess",
      props.locationServicesEnabled ? "granted" : "denied"
    );
    if (
      localStorage.getItem("locationAccess") === "granted" &&
      props.locationServicesEnabled &&
      innerRef
    ) {
      innerRef.current?.getLocation();
    }
  }, [props.locationServicesEnabled, innerRef]);

  React.useEffect(() => {
    if (!geoProps) {
      return;
    }
    if (props.locationServicesEnabled !== geoProps?.isGeolocationEnabled) {
      props.enableLoctionServices(!!geoProps?.isGeolocationEnabled);
    }

    if (geoProps?.coords) {
      if (
        geoProps?.coords.latitude.toFixed(8) !==
          props.currentLocation?.latitude.toFixed(8) ||
        geoProps?.coords.longitude.toFixed(8) !==
          props.currentLocation?.longitude.toFixed(8)
      ) {
        props.setCurrentLocation(geoProps?.coords);
      }
    }
  }, [geoProps]);

  const showBrowserShareLocationHelp = React.useCallback(() => {
    if (isChrome) {
      window.open("https://support.google.com/chrome/answer/142065");
    } else if (isSafari) {
      window.open("https://support.apple.com/en-us/HT204690");
    } else if (isFirefox) {
      window.open(
        "https://support.mozilla.org/ro/kb/does-firefox-share-my-location-websites"
      );
    }
  }, [isChrome, isSafari, isFirefox]);

  const isOpen = React.useCallback(() => {
    return !props.locationServicesEnabled && !dismissNotifications;
  }, [dismissNotifications, props.locationServicesEnabled]);

  const isMobile = useMediaQuery(useTheme().breakpoints.down("sm"));

  return (
    <>
      <GeoLocatedComp
        onSuccess={(e: any) => setError(undefined)}
        onError={(e: any) => setError(e.message)}
        ref={innerRef}
        onGeoProps={(gp: GeolocatedProps) => {
          setGeoProps(gp);
        }}
      ></GeoLocatedComp>
      <Snackbar
        style={isMobile ? { paddingBottom: 56 } : {}}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={isOpen()}
        ContentProps={{
          "aria-describedby": "message-id",
        }}
        message={
          <span id="message-id">
            To upload images, you need to allow access to your location!
            <br />
            <span style={{ color: "red" }}>{error}</span>
          </span>
        }
        action={[
          <Button
            key="help"
            style={{ color: "lightGray" }}
            size="small"
            onClick={showBrowserShareLocationHelp}
          >
            HELP
          </Button>,
          <Button
            key="dismiss"
            style={{ color: "gray" }}
            size="small"
            onClick={() => setDismissNotifications(true)}
          >
            DISMISS
          </Button>,
          <Button
            key="grant"
            color="primary"
            size="small"
            onClick={innerRef.current?.getLocation}
          >
            GRANT
          </Button>,
        ]}
      />
      <div></div>
    </>
  );
};

export const Location = connect(mapStateToProps, mapDispatchToProps)(_Location);

const Geoloc: FC<any> = (
  props: { onGeoProps: (props: GeolocatedProps) => void } & GeolocatedProps
) => {
  React.useEffect(() => {
    props.onGeoProps(props);
  }, [props.isGeolocationAvailable, props.isGeolocationEnabled, props.coords]);

  return <div></div>;
};

const GeoLocatedComp = geolocated({
  positionOptions: {},
  watchPosition: true,
  suppressLocationOnMount: localStorage.getItem("locationAccess") !== "granted",
  isOptimisticGeolocationEnabled: false,
})(Geoloc);
