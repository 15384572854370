import React, { FC } from "react";
import { ChannelRef } from "../models/Channel";
import { connect } from "react-redux";
import { HlMapAppState } from "../redux/reducers";
import { setChannel, showCreateChannel, showAccount } from "../redux/actions";
import { User } from "../models/User";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import "./ChannelSelector.css";
import { makeStyles } from "@material-ui/core/styles";
interface ChannelSelectorProps {
  channels: ChannelRef[];
  channel: ChannelRef | undefined;
  user?: User;
  style?: any;
  onSetChannel: (channel: ChannelRef) => void;
  onShowAccount: typeof showAccount;
  onCreateChannel: () => void;
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    onSetChannel: (channel?: ChannelRef) => dispatch(setChannel(channel)),
    onCreateChannel: () => dispatch(showCreateChannel(true)),
    onShowAccount: (show: boolean) => dispatch(showAccount(show)),
  };
};

const mapStateToProps = (state: HlMapAppState) => {
  return {
    user: state.auth.user,
    channels: state.channel.channels,
    channel: state.channel.channel,
  };
};

const _ChannelSelector: FC<ChannelSelectorProps> = (props) => {
  const onChange = (c?: ChannelRef) => {
    if (!c) {
      return;
    }
    if (c.ref.id !== props.channel?.ref.id) {
      props.onSetChannel(c);
    }
  };

  // const onCreateChannel = () => {
  //   if (props.user?.firebaseAuth.isAnonymous) {
  //     props.onShowAccount(true);
  //   } else {
  //     props.onCreateChannel();
  //   }
  // };

  const classes = makeStyles({
    root: {
      borderBottom: "1px solid white",
    },
    icon: {
      fill: "white",
    },
  })();

  
  return (
    <>
      <div
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          flexDirection: "row",
          ...props.style,
        }}
      >
        <div style={{ color: "white", marginLeft: 8, fontSize: 24 }}>#</div>
        <Select
          disableUnderline={true}
          style={{ color: "white", fontSize: 24 }}
          inputProps={{ classes: { icon: classes.icon } }}
          onChange={(event) => {
            const channel = props.channels.find(
              (c) => c.ref.id === event.target.value
            );
            onChange(channel);
          }}
          value={props.channel ? props.channel.ref.id : ""}
          renderValue={(value) => {
            const channel = props.channels.find((c) => c.ref.id === value);
            return <div>{channel ? channel.data.name : ""}</div>;
          }}
        >
          {props.channels.map((channel) => {
            return (
              <MenuItem key={channel.ref.id} value={channel.ref.id}>
                {channel.data.name}
              </MenuItem>
            );
          })}
        </Select>
        {/* <IconButton
          style={{ color: "white" }}
          onClick={() => onCreateChannel()}
          title="Create channel"
        >
          <Search />
        </IconButton> */}
      </div>
    </>
  );
};

export const ChannelSelector = connect(
  mapStateToProps,
  mapDispatchToProps
)(_ChannelSelector);
