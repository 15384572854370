import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import {  ArrowBack } from "@material-ui/icons";
import { CameraLayer } from "../components/CameraLayer";
import {
  IconButton,
  Stepper,
  Step,
  StepLabel,
  StepButton
} from "@material-ui/core";
import { Data } from "../services/Data";
import { ImageResult } from "../components/ImageResult";
import { ImageEditor } from "../components/ImageEditor";
import { connect } from "react-redux";
import { HlMapAppState } from "../redux/reducers";
import { ChannelRef } from "../models/Channel";
import './Camera.css';
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { UploadContentData } from "../models/Content";

const mapStateToProps = (state: HlMapAppState) => {
  return {
    channel: state.channel.channel,
    channels: state.channel.channels,
    currentLocation: state.location.coordinates
  };
};

export const Camera = connect(mapStateToProps)(
  (props: {
    currentLocation: Coordinates;
    channel: ChannelRef | undefined;
    channels: ChannelRef[];
    style?: React.CSSProperties;
    active: boolean;
  }) => {
      const [step, setStep] = React.useState(0);
    const [baseImage, setBaseImage] = React.useState<string | undefined>();
    const [contentData, setContentData] = React.useState<UploadContentData | undefined>();
    const [loading, setLoading] = React.useState<{
      loading: boolean;
      message?: string;
    }>({
      loading: false
    });
    const steps = ["Take Photo", "Add Toppings", "Upload Art"];
    const onPhotoTaken = (image: string) => {
      setBaseImage(image);
      setStep(1);
    };

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const uploadContentData = async (content: UploadContentData, shareWith: string[]) => {
      if (!props.currentLocation) {
        console.error("no location");
        return;
      }

      return Data.postContent(
        props.currentLocation,
        shareWith,
        content
      ).then(() => {
        setBaseImage(undefined);
        setContentData(undefined);
        setStep(0);
      });
    };

    return (
      <div style={{ flexDirection: 'column', overflow: 'auto', position: 'relative', backgroundColor: 'black', display: "flex", flex: 1, ...props.style }}>
        {!isMobile && (
          <Stepper activeStep={step}>
            {steps.map((label, index) => {
              const stepProps = { completed: index < step };
              const buttonProps = {
                disabled: loading.loading || index >= step
              };
              const labelProps = {};
              return (
                <Step key={label} {...stepProps}>
                  <StepButton
                    key={"button_" + label}
                    {...buttonProps}
                    onClick={() => setStep(index)}
                  >
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </StepButton>
                </Step>
              );
            })}
          </Stepper>
        )}
        <div
          id="contentContainer"
          style={{
            flex: 1,
            position: "relative",
            display: "flex",
            flexDirection: "column",
            overflow: "auto",
          }}
        >
          {props.active && step === 0 && (
            <>
              <CameraLayer
                isLoading={(loading, message) =>
                  setLoading({ loading, message })
                }
                onTakePhoto={onPhotoTaken}
              ></CameraLayer>
            </>
          )}
          {baseImage && (
            <ImageEditor
              baseImage={baseImage}
              style={step !== 1 ? { display: "none" } : {}}
              onFinishEditing={result => {
                setContentData(result);
                setStep(2);
              }}
            ></ImageEditor>
          )}
          {contentData && (
            <ImageResult
              content={contentData}
              channels={props.channels}
              style={step !== 2 ? { display: "none" } : {}}
              uploadContentData={uploadContentData}
              isLoading={(loading, message) => setLoading({ loading, message })}
              shareOn={props.channel ? [props.channel] : []}
            ></ImageResult>
          )}
          {loading.loading && (
            <div
              id="loadingScreen"
              style={{
                top: 0,
                left: 0,
                flex: 1,
                flexDirection: "column",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "absolute",
                width: "100%",
                height: "100%",
                backgroundColor: "black",
                zIndex: 100
              }}
            >
              <CircularProgress size={64}></CircularProgress>
              <div id="loadingMessage" style={{ color: "white" }}>
                {loading.message}
              </div>
            </div>
          )}
        </div>

        {isMobile && step > 0 && (
          <IconButton
            id="backButton"
            onClick={() => {
              setStep(step - 1);
            }}
            style={{
              color: "white",
              position: "absolute",
              top: 0,
              left: 0
            }}
          >
            <ArrowBack />
          </IconButton>
        )}
      </div>
    );
  }
);
