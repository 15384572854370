import React from "react";
import { User } from "./../models/User";
// import Tabs from "@material-ui/core/Tabs";
import { UserProfile } from "./UserProfile";
// import AppBar from "@material-ui/core/AppBar";
// import Tab from "@material-ui/core/Tab";
// import { UserChannels } from "./UserChannels";
// import { IconButton } from "@material-ui/core";
// import { Close } from "@material-ui/icons";
export function UserDetail(props: { onClose?: () => void; user: User }) {
  // const [selectedTabIndex, setSelectedTabIndex] = React.useState(0);

  // const handleChange = (event: React.ChangeEvent<{}>, newIndex: number) => {
  //   setSelectedTabIndex(newIndex);
  // };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          flex: 1,
        }}
      >
        {/* <AppBar position="static">
          <Tabs
            value={selectedTabIndex}
            onChange={handleChange}
            aria-label="user details tabs"
          >
            <Tab label="Profile" />
            <Tab label="Channels" />            
          </Tabs>
          {props.onClose ? (
              <div style={{ position: "absolute", right: 0, top: 0 }}>
                <IconButton aria-label="close" onClick={props.onClose}>
                  <Close />
                </IconButton>
              </div>
            ) : null}
        </AppBar> */}
        <UserProfile
          user={props.user}
          selected={true}
        ></UserProfile>
        {/* <UserChannels selected={selectedTabIndex === 1}></UserChannels> */}
      </div>
    </>
  );
}
