import React from "react";
import {
  Dialog, IconButton 
} from "@material-ui/core";
import { Close } from "@material-ui/icons";

import { Camera } from '../components/Camera';
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import "./CameraDialog.css";


export const CameraDialog = (props: {
  open: boolean;
  onClose: () => void;
}) => {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClose = () => {
    props.onClose();
  };

  return (
    <Dialog
      fullScreen={isMobile}
      maxWidth={"lg"}
      PaperProps={{
        style: {
          backgroundColor: "black",
          position: "relative",
          boxShadow: "none",
          display: "flex",
          flexDirection: "column"
        }
      }}
      onClose={handleClose}
      open={props.open}
    >
      <Camera active={props.open}/>

      <IconButton
          id="closeButton"
          onClick={handleClose}
          style={{
            color: "white",
            position: "absolute",
            top: 0,
            right: 0
          }}
        >
          <Close />
        </IconButton>
    </Dialog>
  );
};

