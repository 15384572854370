import React from "react";
import Camera, { IMAGE_TYPES } from "react-html5-camera-photo";
import { FACING_MODES } from "react-html5-camera-photo";

import IconButton from "@material-ui/core/IconButton";
import { FlipCameraAndroid } from "@material-ui/icons";
export function CameraLayer(props: {
  onTakePhoto: (image: string) => void;
  isLoading: (loading: boolean, message?: string) => void;
}) {
  let _containerRef = React.useRef<HTMLDivElement>(null);
  const [facingMode, setFacingMode] = React.useState(FACING_MODES.USER);
  const [error, setError] = React.useState<string | undefined>();
  const onCameraStart = () => {
    setError(undefined);
    setTimeout(() => props.isLoading(false), 200);
  };

  React.useEffect(() => {
    if (props.isLoading) {
      props.isLoading(true, "Camera loading...");
    }
  }, []);

  const toggleCamera = () => {
    navigator.mediaDevices.enumerateDevices().then((result) => {
      console.log(result);
      switch (result.filter((device) => device.kind === "videoinput").length) {
        case 0:
          alert(
            "Sorry, there are no cameras available. Check your browser settings!"
          );
          break;

        case 1:
          alert("Sorry, it looks like you just have this one camera.");
          break;

        default:
          toggleFacingMode();
          break;
      }
    });
  };

  const toggleFacingMode = () => {
    switch (facingMode) {
      case FACING_MODES.ENVIRONMENT:
        setFacingMode(FACING_MODES.USER);
        break;
      case FACING_MODES.USER:
      default:
        setFacingMode(FACING_MODES.ENVIRONMENT);
    }
  };

  return (
    <>
      <div
        ref={_containerRef}
        style={{
          flex: 1,
          position: "relative",
          display: "flex",
          overflow: "auto",
        }}
      >
        {error ? (
          <div
            style={{
              textAlign: "center",
              color: "white"
            }}
          >
            {error}
          </div>
        ) : (
          <>
            <Camera
              onTakePhoto={(image: string) => {
                props.onTakePhoto(image);
              }}
              onCameraError={(e: any) => {
                props.isLoading(false);
                setError(e.message);
              }}
              isImageMirror={facingMode === FACING_MODES.USER}
              isMaxResolution={true}
              onCameraStart={onCameraStart}
              isSilentMode={true}
              idealFacingMode={facingMode}
              imageType={IMAGE_TYPES.JPG}
              imageCompression={null}
            />
            <IconButton
              onClick={toggleCamera}
              style={{
                color: "white",
                position: "absolute",
                right: 8,
                bottom: 8,
              }}
            >
              <FlipCameraAndroid />
            </IconButton>
          </>
        )}
      </div>
    </>
  );
}
