import React from "react";
import { useGesture } from "react-use-gesture";
import Chick from "../assets/chick.svg";
import NoChick from "../assets/nochick.svg";

export function DragImage(props: {
  x: number;
  y: number;
  imgRef: any;
  containerRef?: React.RefObject<HTMLDivElement>;
}) {
  const minScale = 0.25;
  const maxScale = 5;
  const [[x, y], setPos] = React.useState([props.x, props.y]);
  const [degrees, setRotation] = React.useState(0);
  const [scale, setScale] = React.useState(1);
  const [imageSrc, setImageSrc] = React.useState(Chick);

  const imgRef: React.RefObject<HTMLImageElement> =
    props.imgRef || React.createRef();

  const gestures = useGesture(
    {
      onWheel: (state) => {
        if (state.shiftKey) {
          if (state.xy[0] > (state.memo ? state.memo[0] : 0)) {
            setRotation(degrees + 1);
          } else if (state.xy[0] < (state.memo ? state.memo[0] : 0)) {
            setRotation(degrees - 1);
          }
        } else {
          if (
            state.xy[1] < (state.memo ? state.memo[1] : 0) &&
            scale < maxScale
          ) {
            setScale(scale + 0.05);
          } else if (
            state.xy[1] > (state.memo ? state.memo[1] : 0) &&
            scale > minScale
          ) {
            setScale(scale - 0.05);
          }
        }
        return state.xy || [0, 0];
      },
      onDrag: (state) => {
        if (state.pinching) {
          return;
        }
        let newX = state.offset[0];
        let newY = state.offset[1];

        setPos([newX, newY]);
      }, // fires on drag
      onPinch: (state) => {
        let newScale = state.offset[0] / 100;
        setScale(newScale);
        if (degrees !== state.offset[1]) {
          setRotation(state.offset[1]);
        }
      }, // fires on pinch
    },
    {
      pinch: {
        distanceBounds: {
          min: minScale * 100,
          max: maxScale * 100,
        },
      },
      drag: {
        filterTaps: true,
        bounds: {
          top: imgRef.current ? -imgRef.current.offsetHeight / 2 : 0,
          left: imgRef.current ? -imgRef.current.offsetWidth / 2 : 0,
          right:
            props.containerRef?.current && imgRef.current
              ? props.containerRef.current.offsetWidth -
                imgRef.current.offsetWidth / 2
              : 0,
          bottom:
            props.containerRef?.current && imgRef.current
              ? props.containerRef?.current?.offsetHeight -
                imgRef.current.offsetHeight / 2
              : 0,
        },
      },
    }
  );

  const toggleOverlay = (event: any) => {
    if (imageSrc.includes("nochick")) {
      setImageSrc(Chick);
    } else {
      setImageSrc(NoChick);
    }
  };

  return (
    <div
      {...gestures()}
      onDoubleClick={toggleOverlay}
      style={{
        overflow: "hidden",
        position: "absolute",
        userSelect: "none",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
      }}
    >
      <img
        alt="logo"
        ref={imgRef}
        style={{
          pointerEvents: "none",
          width: 200,
          transformOrigin: "center center",
          transform: `translate(${x}px, ${y}px) rotate(${degrees}deg) scale(${scale})`,
        }}
        src={imageSrc}
      />
    </div>
  );
}
