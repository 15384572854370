import { ContentLocation } from "./../models/Content";
import { HLFirebase } from "./HLFirebase";
import { GeoFirestore } from "geofirestore";
import * as firebase from "firebase/app";
import { setChannelLocations } from "../redux/actions";
import { store } from "../redux/store";
export class _GeoFire {
  static DefaultRadius: number = 1000;
  static DefaultLocation: { lat: number; lng: number } = {
    lat: 16.77348,
    lng: -3.00742
  };

  channelGeoSubscription: (() => void) | undefined = () => { };
  channelContentSubscription: (() => void) | undefined = () => { };
  geo: GeoFirestore;

  constructor() {
    this.geo = new GeoFirestore(HLFirebase.app.firestore());
    // UserService.onUserChanged((user => {
    //     console.log("User changed", user);
    //     if (!user) {
    //         return;
    //     }
    //     const uid = user.ref.id;
    //     this.privateGeoCollection = this.geo.collection('users').doc(uid).collection('images_locations');
    // }));
  }

  updateGeoQueries(
    location = _GeoFire.DefaultLocation,
    radius = _GeoFire.DefaultRadius,
    channelName = "harteliebe"
  ) {
    if (this.channelGeoSubscription) {
      this.channelGeoSubscription();
      this.channelGeoSubscription = undefined;
    }
    if (this.channelContentSubscription) {
      this.channelContentSubscription();
      this.channelContentSubscription = undefined;
    }
    // store.dispatch(setChannelLocations([]));

    const geoCol = this.geo
      .collection("channels")
      .doc(channelName)
      .collection("content_locations");

    const contentCol = HLFirebase.app.firestore().collection(
      `channels/${channelName}/content/`
    );
    const query = geoCol
      .near({
        center: new firebase.firestore.GeoPoint(location.lat, location.lng),
        radius: radius
      })
    // .limit(store.getState().auth.user?.doc ? 1000 : 20);

    this.channelGeoSubscription = query.onSnapshot(data => {
      const contentLocations = data.docs.map(doc => {
        return {
          ref: contentCol.doc(doc.id),
          location: doc.data().coordinates,
          type: doc.data().type,
          icon: doc.data().icon,
        } as ContentLocation;
      });
      console.log("got data");
      store.dispatch(setChannelLocations(contentLocations));
    });
  }
}

export const GeoFire = new _GeoFire();
