import React from "react";
import "./App.css";
import Map from "./components/Map";
import { ImageMarker } from "./models/Image";
import { ContentLocation } from "./models/Content";
import { ChannelRef } from "./models/Channel";
import Dialogs from "./components/Dialogs";
import packageJson from '../package.json';
import {
  Tabs,
  Tab,
  CircularProgress,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import {
  Home,
  Public,
  AddAPhoto,
  AccountBox,
  Loyalty as LoyaltyIcon,
} from "@material-ui/icons";
import { connect } from "react-redux";
import { HlMapAppState } from "./redux/reducers";
import {
  showCamera,
  showLocationBanner,
  setSelectedTab,
} from "./redux/actions";
import { AppHeader } from "./components/AppHeader";
import { ContentList } from "./components/ContentList";
import { Location } from "./components/Location";
import { ReactComponent as Loading } from "./assets/loading.svg";
import { UserService } from "./services/UserService";
import { Account } from "./components/Account";
import { User } from "./models/User";
import { Loyalty } from "./components/Loyalty";
import { isIOS, isIOS13 } from "react-device-detect";

const mapStateToProps = (state: HlMapAppState) => ({
  user: state.auth.user,
  channel: state.channel.channel,
  channels: state.channel.channels,
  locations: state.channel.locations,
  locationServicesEnabled: state.location.locationServicesEnabled,
  showLocationBanner: state.location.showLocationBanner,
  selectedTab: state.settings.selectedTab,
});

const mapDispatchToProps = (dispatch: any) => ({
  onShowCameraDialog: (show: boolean) => dispatch(showCamera(show)),
  onShowLocationBanner: (show: boolean) => dispatch(showLocationBanner(show)),
  onSelectTab: (index: number) => dispatch(setSelectedTab(index)),
});

interface AppProps {
  locations?: ContentLocation[];
  user?: User;
  channel: ChannelRef | undefined;
  channels: ChannelRef[];
  onShowCameraDialog: typeof showCamera;
  onShowLocationBanner: typeof showLocationBanner;
  locationServicesEnabled: boolean;
  onSelectTab: (index: number) => any;
  selectedTab: number;
}

interface AppState {
  isFullScreen: boolean;
  showAccount: boolean;
  images: ImageMarker[];
  showCamera: boolean;
}

const _App = (props: AppProps) => {
  const isMobile = useMediaQuery(useTheme().breakpoints.down("sm"));

  React.useEffect(() => {
    return UserService.subscribeToAuth();
  }, []);

  const getDesktopLayout = () => {
    return (
      <>
        <AppHeader />
        <div
          style={{
            height: "calc(100% - 48px)",
            width: "100%",
            flex: 1,
            display: "flex",
          }}
        >
          <ContentList style={{ flexShrink: 0 }} />
          <Map />
          <Location />
          <Dialogs />
        </div>
      </>
    );
  };

  const getMobileLayout = () => {
    return (
      <>
        <AppHeader />
        <div style={{ flex: 1, display: "flex", overflow: "auto" }}>
          <ContentList
            style={props.selectedTab !== 0 ? { display: "none" } : {}}
          />
          <Map style={props.selectedTab !== 1 ? { display: "none" } : {}} />
          <Loyalty style={props.selectedTab !== 3 ? { display: "none" } : {}} />
          <Account style={props.selectedTab !== 4 ? { display: "none" } : {}} />
        </div>
        <Tabs
          value={props.selectedTab}
          onChange={(event, newValue) => {
            if (
              newValue !== 1 &&
              newValue !== 3 &&
              props.user?.firebaseAuth.isAnonymous
            ) {
              props.onSelectTab(4);
            } else {
              if (newValue === 2) {
                if (!props.locationServicesEnabled) {
                  props.onShowLocationBanner(true);
                } else {
                  props.onShowCameraDialog(true);
                }
              } else {
                props.onSelectTab(newValue);
              }
            }
          }}
          variant="fullWidth"
          indicatorColor="primary"
          textColor="primary"
          aria-label="icon tabs menu"
        >
          <Tab icon={<Home />} aria-label="stream" />
          <Tab icon={<Public />} aria-label="map" />
          <Tab icon={<AddAPhoto />} aria-label="add" />
          <Tab icon={<LoyaltyIcon />} aria-label="loyality" />
          <Tab icon={<AccountBox />} aria-label="account" />
        </Tabs>
        <Dialogs />
        <Location />
      </>
    );
  };

  const needsStandalone = () => {
    return ((isIOS13 || isIOS) && !(window.navigator as any).standalone);
  };

  const getAppLayout = () => {
    return (
      <div
        style={{
          position: "relative",
          top: 0,
          left: 0,
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {(needsStandalone() ||
          !props.user ||
          !props.channel ||
          !props.channels.length) && (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "white",
              zIndex: 10000000,
            }}
          >
            <div className="App-logo">
              <Loading />
            </div>
            <CircularProgress />
            {needsStandalone() && (
              <div style={{ padding: 8 }}>
                On mobile safari this app needs to run in app mode
                <ol>
                  <li>Click on share</li>
                  <li>Add to homescreen</li>
                </ol>
              </div>
            )}
            <div>v{packageJson.version}</div>
          </div>
        )}
        {isMobile ? getMobileLayout() : getDesktopLayout()}
      </div>
    );
  };

  return getAppLayout();
};

export const App = connect(mapStateToProps, mapDispatchToProps)(_App);
