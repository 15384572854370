import { firebaseCredentials } from "../misc/Constants";
import * as firebase from "firebase/app";
import "firebase/firestore";

class _HLFirebase {
  app: firebase.app.App;

  constructor() {
    this.app = firebase.initializeApp(firebaseCredentials());
    if (process.env.REACT_APP_STAGE !== "prod") {
      // this.app = firebase.initializeApp(firebaseCredentials());
      // this.app.firestore().settings({ host: 'localhost:8080', ssl: false })
    } else {
    }
  }
}

export const HLFirebase = new _HLFirebase();
