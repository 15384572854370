import React from "react";
import Fab from "@material-ui/core/Fab";
import AddAPhoto from "@material-ui/icons/AddAPhoto";

import { HlMapAppState } from "../redux/reducers";
import { connect } from "react-redux";
import { showCamera, showAccount, showLocationBanner } from "../redux/actions";
import { withStyles, createStyles } from "@material-ui/core/styles";
import { User } from "../models/User";

import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
interface HUDProps {
  onShowCamera: typeof showCamera;
  onShowAccount: typeof showAccount;
  onShowLocationBanner: typeof showLocationBanner;
  locationServicesEnabled: boolean;
  user?: User;
}

export const _HUD = (props: HUDProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const onAddContent = () => {
    if (props.user?.firebaseAuth.isAnonymous) {
      props.onShowAccount(true);
    } else {
      if (!props.locationServicesEnabled) {
        props.onShowLocationBanner(true);
      } else {
        props.onShowCamera(true);
      }
    }
  };

  return (
    <>
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          display: isMobile ? "none" : "flex",
          pointerEvents: "none",
          justifyContent: "center",
          alignItems: "flex-end",
          padding: 8
        }}
      >
        <Fab
          style={{ pointerEvents: "auto" }}
          color="primary"
          onClick={() => onAddContent()}
        >
          <AddAPhoto />
        </Fab>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onShowCamera: (show: boolean) => dispatch(showCamera(show)),
    onShowAccount: (show: boolean) => dispatch(showAccount(show)),
    onShowLocationBanner: (show: boolean) => dispatch(showLocationBanner(show)),
  };
};

const styles = createStyles({});

const mapStateToProps = (state: HlMapAppState) => {
  return { user: state.auth.user, locationServicesEnabled: state.location.locationServicesEnabled };
};
export const HUD = withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(_HUD)
);
