import React, { FC } from "react";
import { CameraDialog } from "../dialogs/CameraDialog";
import { ChannelDialog } from "../dialogs/ChannelDialog";
import { AccountDialog } from "../dialogs/AccountDialog";
import { LoyaltyDialog } from "../dialogs/LoyaltyDialog";
import { HlMapAppState } from "../redux/reducers";
import {
  showAccount,
  showCamera,
  showCreateChannel,
  showContentDetail,
  showLocationDetail,
  panToContentLocation,
  showLoyalty,
} from "../redux/actions";
import { ContentDetail } from "../dialogs/ContentDetail";
import { connect } from "react-redux";
import { Content, ContentLocation } from "../models/Content";

interface DialogsProps {
  showCamera: boolean;
  showAccount: boolean;
  showCreateChannel: boolean;
  showLocationDetail: boolean;
  showContentDetail: boolean;
  showLoyalty: boolean;
  content: Content[];
  locations: ContentLocation[];
  user: any;
  onShowCamera: typeof showCamera;
  onShowAccount: typeof showAccount;
  onShowCreateChannel: typeof showCreateChannel;
  onShowLocationDetail: typeof showLocationDetail;
  onShowContentDetail: typeof showContentDetail;
  onShowLoyalty: typeof showLoyalty;
  onSelectContentLocation: (cl: ContentLocation) => void;
}

const Dialogs: FC<DialogsProps> = ({
  showAccount,
  showCamera,
  showCreateChannel,
  showLocationDetail,
  showContentDetail,
  showLoyalty,
  onShowCamera,
  onShowAccount,
  onShowCreateChannel,
  onShowLocationDetail,
  onShowContentDetail,
  onShowLoyalty,
  onSelectContentLocation,
  locations,
  content,
  user,
}) => {
  return (
    <>
      {showCamera && (
        <CameraDialog open={showCamera} onClose={() => onShowCamera(false)} />
      )}

      {(showLocationDetail || showContentDetail) && (
        <ContentDetail
          open={showLocationDetail || showContentDetail}
          user={user}
          onClose={(c) => {
            if (c && c.ref && c.data) {
              const cl = {
                ref: c?.ref,
                type: c.data.image ? "image" : "text",
                location: c?.data.location,
              } as ContentLocation;              
              onSelectContentLocation(cl);
            }
            onShowLocationDetail(false);
            onShowContentDetail(false);
          }}
          content={showContentDetail ? content : locations}
        ></ContentDetail>
      )}
      {showCreateChannel && (
        <ChannelDialog
          open={showCreateChannel}
          onClose={() => onShowCreateChannel(false)}
        />
      )}
      {showAccount && (
        <AccountDialog
          open={showAccount}
          onClose={() => {
            onShowAccount(false);
          }}
        />
      )}
      {showLoyalty && (
        <LoyaltyDialog
          open={showLoyalty}
          onClose={() => {
            onShowLoyalty(false);
          }}
        />
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onSelectContentLocation: (cl: ContentLocation) =>
      dispatch(panToContentLocation(cl)),
    onShowLocationDetail: (show: boolean) => dispatch(showLocationDetail(show)),
    onShowContentDetail: (show: boolean) => dispatch(showContentDetail(show)),
    onShowAccount: (show: boolean) => dispatch(showAccount(show)),
    onShowCamera: (show: boolean) => dispatch(showCamera(show)),
    onShowCreateChannel: (show: boolean) => dispatch(showCreateChannel(show)),
    onShowLoyalty: (show: boolean) => dispatch(showLoyalty(show)),
  };
};

const mapStateToProps = (state: HlMapAppState) => {
  return {
    user: state.auth.user,
    channel: state.channel.channel,
    content: state.channel.content,
    locations: state.channel.locations,
    ...state.dialog,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dialogs);
