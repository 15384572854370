import { ReceivedFriendRequest } from "./../models/User";
import { SentFriendRequest } from "../models/User";
import { firebaseFunctionsUrl } from "../misc/Constants";
import { UserService } from "../services/UserService";
import { UploadContentData } from "../models/Content";
export const apiBaseUrl = firebaseFunctionsUrl();
export const apiCalls = {
  delete: {
    sentFriendRequests: async (requests: SentFriendRequest[]) => {
      if (!requests || !requests.length) {
        return;
      }
      const auth = await authHeaders();
      const options: RequestInit = {
        headers: {
          "Content-Type": "application/json",
          ...auth,
        },
        method: "DELETE",
        body: JSON.stringify(requests.map((request) => request.ref.id)),
      };
      return fetch(apiUrls.delete.sentFriendRequests(), options);
    },
  },
  put: {
    declineReceivedFriendRequests: async (
      requests: ReceivedFriendRequest[]
    ) => {
      if (!requests || !requests.length) {
        return;
      }
      const auth = await authHeaders();
      const options: RequestInit = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          ...auth,
        },
        body: JSON.stringify(requests.map((r) => r.ref.id)),
      };
      return fetch(apiUrls.put.declineReceivedFriendRequests(), options);
    },
    acceptReceivedFriendRequests: async (requests: ReceivedFriendRequest[]) => {
      if (!requests || !requests.length) {
        return;
      }
      const auth = await authHeaders();
      const options: RequestInit = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          ...auth,
        },
        body: JSON.stringify(requests.map((r) => r.ref.id)),
      };
      return fetch(apiUrls.put.acceptReceivedFriendRequests(), options);
    },
    friendRequest: async (code: string) => {
      if (!code || !code.length) {
        return;
      }
      const auth = await authHeaders();
      const options: RequestInit = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          ...auth,
        },
        body: JSON.stringify({ code }),
      };
      return fetch(apiUrls.put.friendRequests(), options);
    },
  },
  post: {
    content: async (
      lat: number,
      lng: number,
      shareWith: string[],
      content?: UploadContentData
    ) => {
      const auth = await authHeaders();
      const options: RequestInit = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          ...auth,
        },
        body: JSON.stringify({ content, shareWith, lat, lng }),
      };
      return fetch(apiUrls.post.content(), options);
    },
    joinChannel: async (channel: string) => {
      const auth = await authHeaders();
      const options: RequestInit = {
        headers: {
          "Content-Type": "application/json",
          ...auth,
        },
        method: "POST",
        body: JSON.stringify({ channel }),
      };
      return fetch(apiUrls.post.joinChannel(), options);
    },
    leaveChannel: async (channel: string) => {
      const auth = await authHeaders();
      const options: RequestInit = {
        headers: {
          "Content-Type": "application/json",
          ...auth,
        },
        method: "POST",
        body: JSON.stringify({ channel }),
      };
      return fetch(apiUrls.post.leaveChannel(), options);
    },
    addChannelMember: async (channel: string, member: string) => {
      const auth = await authHeaders();
      const options: RequestInit = {
        headers: {
          "Content-Type": "application/json",
          ...auth,
        },
        method: "POST",
        body: JSON.stringify({ channel, member }),
      };
      return fetch(apiUrls.post.addChannelMember(), options);
    },
    createChannel: async (name: string, visibility: string) => {
      const auth = await authHeaders();
      const options: RequestInit = {
        headers: {
          "Content-Type": "application/json",
          ...auth,
        },
        method: "POST",
        body: JSON.stringify({ name, visibility }),
      };
      return fetch(apiUrls.post.createChannel(), options);
    },
    sessionLogin: async (idToken: string, csrfToken?: string) => {
      const options: RequestInit = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ idToken, csrfToken }),
        credentials: "include",
      };
      return fetch(apiUrls.post.sessionLogin(), options);
    },
    sessionLogout: async () => {
      const auth = await authHeaders();
      const options: RequestInit = {
        headers: {
          "Content-Type": "application/json",
          ...auth,
        },
        method: "POST",
        credentials: "include",
      };
      return fetch(apiUrls.post.sessionLogout(), options);
    },
    reaction: async (
      reaction: string,
      channelId: string,
      contentId: string
    ) => {
      if (
        !reaction ||
        !reaction.length ||
        !channelId ||
        !channelId.length ||
        !contentId ||
        !contentId.length
      ) {
        return;
      }
      const auth = await authHeaders();
      const options: RequestInit = {
        headers: {
          "Content-Type": "application/json",
          ...auth,
        },
        method: "POST",
        body: JSON.stringify({
          content: contentId,
          channel: channelId,
          reaction: reaction,
        }),
      };
      return fetch(apiUrls.post.reaction(), options);
    },
    avatar: async (data: FormData) => {
      if (!data) {
        return;
      }
      const auth = await authHeaders();
      const options: RequestInit = {
        method: "POST",
        headers: {
          ...auth,
        },
        body: data,
      };
      return fetch(apiUrls.post.avatar(), options);
    },
    register: async (displayName: string, email: string, password: string) => {
      const auth = await authHeaders();
      const options: RequestInit = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          ...auth,
        },
        body: JSON.stringify({
          displayName,
          email,
          password,
        }),
      };
      return fetch(apiUrls.post.register(), options);
    },
  },
};

async function authHeaders() {
  const token = await UserService.getAuthToken();
  return { Authorization: "Bearer " + token };
}

export const apiUrls = {
  delete: {
    sentFriendRequests: () => `${apiBaseUrl}/friendRequests/`,
  },
  put: {
    declineReceivedFriendRequests: () => `${apiBaseUrl}/friendRequests/decline`,
    acceptReceivedFriendRequests: () => `${apiBaseUrl}/friendRequests/accept`,
    friendRequests: () => `${apiBaseUrl}/friendRequests/`,
  },
  get: {
    share: (code: string) => {
      return `https://app.harteliebe.com/friendRequest/${code}`;
    },
    image: (id: string, width?: number, height?: number) => {
      return `${apiBaseUrl}/content/${id}?width=${width}&height=${height}`;
    },
    avatar: (uid: string) => {
      return `${apiBaseUrl}/user/avatar/${uid}`;
    },
  },
  post: {
    content: () => {
      return `${apiBaseUrl}/content`;
    },
    reaction: () => `${apiBaseUrl}/content/reaction`,
    joinChannel: () => {
      return `${apiBaseUrl}/channels/join`;
    },
    leaveChannel: () => {
      return `${apiBaseUrl}/channels/leave`;
    },
    createChannel: () => {
      return `${apiBaseUrl}/channels/create`;
    },
    addChannelMember: () => {
      return `${apiBaseUrl}/channels/addMember`;
    },
    sessionLogin: () => {
      return `${apiBaseUrl}/session/login`;
    },
    sessionLogout: () => {
      return `${apiBaseUrl}/session/logout`;
    },
    avatar: () => {
      return `${apiBaseUrl}/user/avatar`;
    },
    register: () => {
      return `${apiBaseUrl}/user/register`;
    }
  },
};
