import { Content, ContentLocation, ContentData, UploadContentData } from "./../models/Content";
import { Image, ImageRef } from "../models/Image";
import { apiCalls } from "../services/URLService";
export class _Data {

  observeImageWithFunction = (
    image: ImageRef,
    fn: (image: Image | undefined) => void
  ) => {
    if (!image) {
      return;
    }
    return image.ref.onSnapshot(async snap => {
      let image;
      if (snap.exists) {
        image = { ref: snap.ref, ...snap.data() } as Image;
      }
      fn(image);
    });
  };

  observeContentWithFunction = (
    contentLocation: ContentLocation | Content,
    fn: (content: Content | null) => void
  ) => {
    if (!contentLocation) {
      return;
    }
    return contentLocation.ref.onSnapshot(snap => {
      const content = {
        ref: snap.ref,
        data: snap.data() as ContentData
      } as Content;
      fn(content);
    }, err => {
      console.log(err);
    });
  };

  postContent = async (
    coords: Coordinates,
    shareWith: string[],
    content?: UploadContentData
  ) => {
    if (!content?.image?.length && !content?.text?.length) {
      console.error("no data provided");
      return;
    }

    if (!coords || !coords.latitude || !coords.longitude) {
      console.error("no location");
      return;
    }

    return apiCalls.post.content(
      coords.latitude,
      coords.longitude,
      shareWith,
      content
    );
  };

  // copyImagesToPublicChannel = async () => {
  //   const batch = HLFirebase.app.firestore().batch();
  //   HLFirebase.app
  //     .firestore()
  //     .collection("images_locations")
  //     .onSnapshot(async snaps => {
  //       snaps.docs.forEach(snap => {
  //         const ref = HLFirebase.app
  //           .firestore()
  //           .collection("channels")
  //           .doc("harteliebe")
  //           .collection("content")
  //           .doc(snap.ref.id);
  //         batch.set(ref, snap.data());
  //       });
  //       await batch.commit();
  //     });
  // };

  // migrateImages = async () => {
  //   console.log("migrating images");
  //   const images = await HLFirebase.app
  //     .firestore()
  //     .collection("images")
  //     .get();
  //   const operations: Promise<any>[] = [];
  //   images.forEach(async img => {
  //     console.log("addming img", img.id);
  //     const op = HLFirebase.app
  //       .firestore()
  //       .collection("users")
  //       .doc("phlipmode")
  //       .collection("content")
  //       .add({
  //         createdAt: img.data().createdAt,
  //         creator: {
  //           name: "phlipmode",
  //           ref: HLFirebase.app
  //             .firestore()
  //             .collection("users")
  //             .doc("phlipmode")
  //         },
  //         image: {
  //           id: img.id,
  //           isLandscape: false
  //         },
  //         location: img.data().location,
  //         modifiedAt: firebase.firestore.FieldValue.serverTimestamp(),
  //         reactions: [],
  //         shareWith: ["harteliebe"],
  //         text: null
  //       })
  //       .then(() => {
  //         console.log("migrated", img.id);
  //       })
  //       .catch(e => {
  //         console.log("migrated error", e);
  //       });

  //     operations.push(op);
  //   });
  //   await Promise.all(operations);
  // };

  // migrateToFirestore = async () => {
  //     const images = await this.app.database().ref('images').once('value');
  //     const operations: Promise<any>[] = [];
  //     images.forEach(imageSnap => {
  //         if (imageSnap.exists && imageSnap.key) {
  //             const operation = this.app.firestore().collection('images').doc(imageSnap.key).set(imageSnap.val());
  //             operations.push(operation);
  //         }
  //     });
  //     await Promise.all(operations);
  // }

  // createLocationData = async () => {
  //     const images = await this.app.firestore().collection('images').get();
  //     images.docs.forEach(doc => {

  //     });
  // }

  // listAvailableImages = async () => {
  //     const fileList = await HLFirebase.app.storage().ref('images/original').list();

  //     const images = await HLFirebase.app.firestore().collection('images').get();
  //     const removeImages: any[] = [];
  //     images.docs.forEach(image => {
  //         let file = fileList.items.some(item => item.fullPath.includes(image.ref.id));
  //         if (!file) {
  //             const promise = async () => {
  //                 await HLFirebase.app.firestore().collection('deleted_images').doc(image.ref.id).set(image.data());
  //                 await image.ref.delete();
  //             }
  //             removeImages.push(promise());
  //         }
  //     });
  //     await Promise.all(removeImages);
  //     console.log("Images cleaned up");
  // }

  // generateCommands = async () => {
  //     const legacyFileList = await HLFirebase.app.storage().ref('images').list();
  //     let cmd: string = '';
  //     legacyFileList.items.forEach(item => {
  //         cmd += `gsutil mv gs://harte-liebe.appspot.com/${item.fullPath} gs://harte-liebe.appspot.com/images/original/${item.name.replace('.jpg', '')}\n`;
  //     });
  //     console.log(cmd);
  // }

  // "createdAt" : 5.141545921860249E8,
  // "hardCount" : 39,
  // "imageStoragePath" : "images/-Khxduh7Hq7apxplSTgH.jpg",
  // "isLandscape" : false,
  // "lat" : 53.55108206039505,
  // "long" : 10.00135422458335,
  // "loveCount" : 23,
  // "thumbnailStoragePath" : "thumbnails/-Khxduh7Hq7apxplSTgH.jpg",
  // "uid" : "not-available"

  // hardCount: number,
  // isLandscape: boolean,
  // loveCount: number,
  // location: firebase.firestore.GeoPoint
  // creator: firebase.firestore.DocumentReference
  // modifiedAt: firebase.firestore.FieldValue
  // importImages = async () => {
  //     const images = require('../assets/harte-liebe-96762-images-export.json');
  //     for (const imageKey of Object.keys(images)) {
  //         const image = images[imageKey];
  //         let date = new Date(new Date("2001-01-01").getTime() + image.createdAt);
  //         const newImage: ImageData = {
  //             createdAt: firebase.firestore.Timestamp.fromDate(date),
  //             hardCount: image.hardCount,
  //             loveCount: image.loveCount,
  //             isLandscape: image.isLandscape,
  //             location: new firebase.firestore.GeoPoint(image.lat, image.long),
  //             creator: HLFirebase.app.firestore().collection('users').doc(image.uid),
  //             modifiedAt: firebase.firestore.Timestamp.now()
  //         }
  //         console.log("migrating", imageKey, newImage);
  //         await HLFirebase.app.firestore().collection('images').doc(imageKey).set(newImage);
  //     }
  // }
}

export const Data = new _Data();
