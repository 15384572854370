import React from "react";
import { Switch, Button, FormControlLabel } from "@material-ui/core";
import { HarteLiebeLayer } from "../Layers/HarteLiebeLayer";
import "./ImageEditor.css";
import { NavigateNext } from "@material-ui/icons";
import { UploadContentData } from "../models/Content";
import { Layer, availableLayers, LayerRefProps } from "./DynamicContentLayers";

export function ImageEditor(props: {
  baseImage?: string;
  onFinishEditing: (content: UploadContentData) => void;
  style: React.CSSProperties;
}) {
  const dynamicLayerRefs = React.useRef<(LayerRefProps | null)[]>([]);
  const [dynamicLayers, setDynamicLayers] = React.useState<Layer[]>([]);
  const _mergerRef = React.useRef<HTMLCanvasElement>(null);
  const layerContainerRef = React.useRef<HTMLDivElement>(null);
  const staticLayerRefs = React.useRef<(LayerRefProps | null)[]>([]);
  const [isLandscape, setIsLandscape] = React.useState(false);
  const staticLayers: Layer[] = [
    {
      id: "harteliebe",
      name: "harteliebe",
      render: () => (
        <HarteLiebeLayer
          key="harteliebe"
          containerRef={layerContainerRef}
          ref={(ref) => (staticLayerRefs.current[0] = ref)}
        />
      ),
    },
  ];

  const toggleLayer = (layer: Layer) => {
    const index = dynamicLayers.findIndex((m) => m.id === layer.id);
    if (index >= 0) {
      // Remove existing layer
      const newLayers = Array.from(dynamicLayers);
      newLayers.splice(index, 1);
      setDynamicLayers(newLayers);
    } else {
      // Add new layer
      const newLayer = availableLayers.find((l) => l.id === layer.id);
      if (newLayer) {
        setDynamicLayers([...dynamicLayers, newLayer]);
      }
    }
  };

  const loadImage = (img: HTMLImageElement, src: string) => {
    return new Promise((res, rej) => {
      img.onload = () => {
        res();
      };
      img.onerror = () => {
        rej();
      };
      img.src = src;
    });
  };

  const createFinalImage = async () => {
    if (!props.baseImage || !_mergerRef.current) {
      return;
    }
    const cameraImage = new Image();
    await loadImage(cameraImage, props.baseImage);
    const cameraWidth = cameraImage.width;
    const cameraHeight = cameraImage.height;

    _mergerRef.current.width = cameraWidth;
    _mergerRef.current.height = cameraHeight;

    const context = _mergerRef.current.getContext("2d")!;

    context.drawImage(cameraImage, 0, 0, cameraWidth, cameraHeight);
    for (let l of staticLayerRefs.current!) {
      await l?.merge(context, cameraWidth, cameraHeight);
    }

    props.onFinishEditing({
      image: _mergerRef.current!.toDataURL("image/jpeg"),
      dynamicLayers: dynamicLayers.map((l) => l.id),
    });
  };

  const baseImageLoaded = (event: any) => {
    const img = event.target;
    setIsLandscape(img.width > img.height);
  };

  return (
    <div id="image_editor_outer_container" style={props.style}>
      <canvas
        id="image_editor_merger_ref"
        ref={_mergerRef}
        style={{ display: "none" }}
      ></canvas>
      <div id="image_editor_inner_container">
        <div
          ref={layerContainerRef}
          id="image_editor_image_container"
        >
          <img
            id="image_editor_base_image"
            onLoad={baseImageLoaded}
            alt="Base"
            src={props.baseImage}
          />
          {staticLayers.map((l) =>
            l.render(layerContainerRef, staticLayerRefs)
          )}
          {dynamicLayers.map((l) =>
            l.render(layerContainerRef, dynamicLayerRefs)
          )}
        </div>
        <div className="image_editor_button_container">
          {availableLayers.map((l) => {
            return (
              <>
                <FormControlLabel
                  className="switch"
                  control={
                    <Switch
                      color="primary"
                      checked={dynamicLayers.some((dl) => dl.id === l.id)}
                      onChange={() => {
                        toggleLayer(l);
                      }}
                      name={l.name}
                    />
                  }
                  label={l.name}
                />
              </>
            );
          })}
          <Button
            endIcon={<NavigateNext />}
            className="nextButton"
            variant="contained"
            color="primary"
            onClick={createFinalImage}
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  );
}
