import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  Button,
  Select,
  MenuItem,
  ListItem,
  InputAdornment,
  FormControl,
  InputLabel,
  List,
  CircularProgress
} from "@material-ui/core";

import { LockOpen, Lock } from "@material-ui/icons";
import { SearchService } from "../services/SearchService";
import { ChannelService } from "../services/ChannelService";
import { User } from "../models/User";
import { ChannelSearchResult, ChannelRef } from "../models/Channel";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { connect } from "react-redux";
import { HlMapAppState } from "../redux/reducers";
import { setChannel } from "../redux/actions";
import useStyles from "./ChannelDialogStyles";
const mapStateToProps = (state: HlMapAppState) => {
  return {
    availableChannels: state.channel.channels
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  setChannel: (channel: ChannelRef) => dispatch(setChannel(channel))
});

const _ChannelDialog = (props: {
  user?: User;
  open: boolean;
  onClose: () => void;
  setChannel: (channel: ChannelRef) => void;
  availableChannels: ChannelRef[];
}) => {
  const isMobile = useMediaQuery(useTheme().breakpoints.down("sm"));

  const onClose = () => {
    props.onClose();
  };

  const [visibility, setVisibility] = React.useState("public");
  const [error, setError] = React.useState<any>();
  const [isSearching, setIsSearching] = React.useState(false);
  const [isCreating, setIsCreating] = React.useState(false);
  const [isJoining, setIsJoining] = React.useState(false);
  const [newChannelName, setNewChannelName] = React.useState("");
  const [inputValue, setInputValue] = React.useState("");
  const [foundChannels, setFoundChannels] = React.useState<
    ChannelSearchResult[]
  >([]);
  const [timeoutCb, setTimeoutCb] = React.useState<any>();

  const search = async (value?: string) => {
    const cb = setTimeout(() => {
      setIsSearching(true);
      setNewChannelName(value || "");
      SearchService.channels(value)
        .then(result => {
          setFoundChannels(
            result.filter(
              c => !props.availableChannels.some(r => c.id === r.ref.id)
            )
          );
          setIsSearching(false);
        })
        .catch(e => {
          console.log(e);
          setFoundChannels([]);
          setIsSearching(false);
        });
    }, 700);
    setTimeoutCb(cb);
  };

  const searchChannel = async (value?: string) => {
    setInputValue(value || "");
    setFoundChannels([]);
    clearTimeout(timeoutCb);
    if (value && value.length > 2) {
      setError(null);
      await search(value);
    } else {
      setNewChannelName("");
      if (!error) {
        setError({ message: "Please enter a minimum of 3 characters" });
      }
    }
  };

  const reset = () => {
    setIsSearching(false);
    setInputValue("");
    setNewChannelName("");
  };

  const joinedChannel = () => {
    return props.availableChannels.some(r => r.ref.id === newChannelName);
  };

  const canCreate = () => {
    return (
      !props.availableChannels.some(r => r.ref.id === newChannelName) &&
      !foundChannels.some(c => c.id === newChannelName) &&
      !isSearching &&
      newChannelName.length > 2
    );
  };

  const classes = useStyles();
  return (
    <Dialog
      onClose={onClose}
      open={props.open}
      fullScreen={isMobile}
      PaperProps={{ style: { width: 400, height: 400 } }}
    >
      <DialogTitle>Find your channel</DialogTitle>
      {isCreating && (
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <div>Creating channel {newChannelName}</div>
          <CircularProgress />
        </div>
      )}
      {isJoining && (
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <div>Joining channel {newChannelName}</div>
          <CircularProgress />
        </div>
      )}
      {!isCreating && !isJoining && (
        <DialogContent>
          <DialogContentText>
            Search for existing channels and join them or create a new one!
          </DialogContentText>
          <div className={classes.innerContainer}>
            <div className={classes.search}>
              <TextField
                error={!!error}
                value={inputValue}
                type="search"
                variant="outlined"
                label="Name of Channel"
                placeholder="harteliebe"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">#</InputAdornment>
                  )
                }}
                helperText={error ? error.message : ""}
                onChange={event => searchChannel(event.target.value)}
              />
            </div>
            {joinedChannel() && (
              <DialogContentText>
                You are already a member of #{newChannelName}. Join some other
                channels!
              </DialogContentText>
            )}
            {canCreate() && (
              <>
                <div className={classes.create}>
                  <FormControl style={{ flex: 1, margin: 8 }}>
                    <InputLabel shrink>Visibility</InputLabel>
                    <Select
                      value={visibility}
                      fullWidth
                      // inputProps={{ style: { display: 'flex', flexDirection: 'row', alignItems: 'center'}}}
                      onChange={event =>
                        setVisibility(event.target.value as string)
                      }
                    >
                      <MenuItem key="public" value={"public"}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "row"
                          }}
                        >
                          <LockOpen />
                          <div style={{ flex: 1 }}>Public</div>
                        </div>
                      </MenuItem>
                      <MenuItem key="private" value={"private"}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "row"
                          }}
                        >
                          <Lock />
                          <div style={{ flex: 1 }}>Private</div>
                        </div>
                      </MenuItem>
                    </Select>
                  </FormControl>
                  <Button
                    color="primary"
                    variant="contained"
                    style={{
                      flex: 1,
                      margin: 8,
                      display: "flex",
                      justifyContent: "center"
                    }}
                    disabled={error || newChannelName.length < 3 || isSearching}
                    onClick={() => {
                      setIsCreating(true);
                      ChannelService.createChannel(newChannelName, visibility)
                        .then(() => {
                          if (!props.user) {
                            return;
                          }
                          reset();
                          setIsCreating(false);
                          setIsJoining(true);
                          ChannelService.getChannelRef(
                            newChannelName,
                            props.user.firebaseAuth.uid
                          ).then(c => {
                            if (c) {
                              props.setChannel(c);
                              onClose();
                            }
                            setIsJoining(false);
                          });
                        })
                        .catch(e => {
                          setError(e);
                          setIsCreating(false);
                        });
                    }}
                  >
                    {isSearching ? <CircularProgress /> : <div>{"Create"}</div>}
                  </Button>
                </div>
                <DialogContentText>
                  The channel #{newChannelName} doesn't exist. Go ahead and
                  create a new channel!
                </DialogContentText>
              </>
            )}
            {foundChannels.length ? (
              <>
                <DialogContentText>
                  Found {foundChannels.length} channels for {newChannelName}
                </DialogContentText>
                <div className={classes.list}>
                  <>
                    <List>
                      {foundChannels.map(c => (
                        <ListItem key={c.id} className={classes.listItem}>
                          {c.source.visibility === "public" ? (
                            <LockOpen />
                          ) : (
                            <Lock />
                          )}
                          <div style={{ flex: 1 }}>#{c.source.name}</div>
                          {c.source.visibility === "public" ? (
                            <Button
                              size="small"
                              variant="outlined"
                              onClick={() => {
                                setIsJoining(true);
                                ChannelService.joinChannel(c).finally(() => {
                                  searchChannel(newChannelName).finally(() => {
                                    setIsJoining(false);
                                  });
                                });
                              }}
                            >
                              Join
                            </Button>
                          ) : (
                            <Button size="small" variant="outlined">
                              Request
                            </Button>
                          )}
                        </ListItem>
                      ))}
                    </List>
                  </>
                </div>
              </>
            ) : (
              <>{isSearching && <CircularProgress />}</>
            )}
          </div>
        </DialogContent>
      )}
    </Dialog>
  );
};

export const ChannelDialog = connect(
  mapStateToProps,
  mapDispatchToProps
)(_ChannelDialog);
