import React, { ReactNode } from "react";
import Confetti from "react-confetti";

export interface ConfettiLayerRef {
  merge: (
    context: CanvasRenderingContext2D,
    width: number,
    height: number
  ) => Promise<void>;
}

export const ConfettiLayer = React.forwardRef<ConfettiLayerRef, {
      children?: ReactNode;
      containerRef?: React.RefObject<HTMLDivElement>;
    }>(
  (props, ref) => {
    const _ref = React.useRef<HTMLCanvasElement>(null);

    React.useImperativeHandle(ref, () => ({
      merge
    }));

    const loadImage = (img: HTMLImageElement, src: string) => {
      return new Promise((res, rej) => {
        img.onload = () => {
          res();
        };
        img.onerror = () => {
          rej();
        };
        img.src = src;
      });
    };
    const merge = async (
      context: CanvasRenderingContext2D,
      width: number,
      height: number
    ) => {
      const mergedImage = new Image(width, height);
      await loadImage(mergedImage, _ref.current!.toDataURL());
      context.drawImage(mergedImage, 0, 0, width, height);
    };

    return (
      <Confetti
        canvasRef={_ref}
        key="confetti"
        id="confetti"
        opacity={0.75}
        style={{ position: "absolute", top: 0, left: 0, height: '100%', width: '100%', pointerEvents: "none" }}
      />
    );
  }
);
