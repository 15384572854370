import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import { useGoogleMap } from '@react-google-maps/api';

export default function MapControl(
    props:
        { position: google.maps.ControlPosition, children: any }
) {
    const map = useGoogleMap();

    const controlDiv = document.createElement('div');

    useEffect(() => {
        if (map) {
            const controls = map.controls[props.position];
            if (controls) {
                const index = controls.getLength();
                controls.push(controlDiv);
                return () => {
                    controls.removeAt(index);
                };
            }
        }
    });



    return createPortal(
        <div style={{ marginLeft: 16, marginTop: 16 }}>{props.children}</div>,
        controlDiv
    );
}
