import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { Account } from '../components/Account';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
export function AccountDialog(props: { open: boolean, onClose: () => void }) {

    const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));
    
    const onClose = () => {
        props.onClose();
    }

    

    return (
        <Dialog fullScreen={isMobile} onClose={onClose} open={props.open}>
            <Account />
        </Dialog >
    );
}
