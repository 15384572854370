import { makeStyles } from "@material-ui/core/styles";

export default makeStyles({
  innerContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1
  },
  search: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: 64
  },
  list: {
    height: "50%",
    alignItems: "center",
    overflowY: "auto"
  },
  listItem: {
    borderBottom: "1px solid lightgray",
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  create: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "stretch"
  }
});
