import { ChannelRef } from "./../models/Channel";
import { FilterSetting } from "./../models/Settings";
import { ContentLocation, Content } from "./../models/Content";
import { User } from "./../models/User";
import {
  AuthAction,
  AuthActionType,
  SettingsAction,
  SettingsActionType,
  ChannelAction,
  ChannelActionType,
  MapAction,
  LocationAction,
  LocationActionType,
  DialogAction,
  DialogActionType,
  MapActionType,
  ListAction,
  ListActionType,
} from "./actions";
import { combineReducers } from "redux";
export interface HlMapAppState {
  map: MapState;
  dialog: DialogState;
  list: ListState;
  location: LocationState;
  auth: AuthState;
  channel: ChannelState;
  settings: SettingsState;
}

export interface AuthState {
  user?: User;
}
const initialAuthState: AuthState = {
  user: undefined,
};

export interface SettingsState {
  mapContentFilter: FilterSetting[];
  selectedTab: number;
}
const initialSettingsState: SettingsState = {
  mapContentFilter: [],
  selectedTab: 1 // Map Screen
};

export interface ChannelState {
  channel?: ChannelRef;
  channels: ChannelRef[];
  content: Content[];
  locations: ContentLocation[];
}

const initialChannelState: ChannelState = {
  channel: undefined,
  channels: [],
  content: [],
  locations: [],
};
export interface MapState {
  selectedContentLocations: ContentLocation[];
  panToContentLocation?: ContentLocation;
  zoomToContentLocation?: ContentLocation;
  zoom: number;
  searchRadius?: number;
  searchLocation?: Coordinates;
  searchPlace?: google.maps.places.PlaceResult;
}

const initalMapState: MapState = {
  selectedContentLocations: [],
  panToContentLocation: undefined,
  zoomToContentLocation: undefined,
  zoom: 7,
  searchRadius: 1000,
  searchLocation: undefined,
  searchPlace: undefined,
};

export interface DialogState {
  selectContentLocation?: ContentLocation;
  showLocationDetail: boolean;
  showContentDetail: boolean;
  showCreateChannel: boolean;
  showLeaveChannel: boolean;
  showAccount: boolean;
  showCamera: boolean;
  showLoyalty: boolean;
}
const initialDialogState: DialogState = {
  selectContentLocation: undefined,
  showLocationDetail: false,
  showContentDetail: false,
  showCreateChannel: false,
  showLeaveChannel: false,
  showAccount: false,
  showCamera: false,
  showLoyalty: false,
};

export interface LocationState {
  locationServicesEnabled: boolean;
  coordinates: Coordinates;
  showLocationBanner: boolean;
}
const initialLocationState: LocationState = {
  locationServicesEnabled: false,
  showLocationBanner: false,
  coordinates: {
    latitude: 51.1657,
    longitude: 10.4515,
    accuracy: 0,
    altitude: 0,
    altitudeAccuracy: 0,
    heading: 0,
    speed: 0,
  },
};

export interface ListState {
  scrollToContentLocation: ContentLocation;
  show: boolean;
}
const initalListState = {
  scrollToContentLocation: undefined,
  show: true,
};

function dialog(state = initialDialogState, action: DialogAction) {
  switch (action.type) {
    case DialogActionType.SHOW_LOCATION_DETAIL:
      return Object.assign({}, state, {
        showLocationDetail: action.show,
      });

    case DialogActionType.SHOW_CONTENT_DETAIL:
      return Object.assign({}, state, {
        showContentDetail: action.show,
      });

    case DialogActionType.SELECT_MAP_MARKER:
      return Object.assign({}, state, {
        selectContentLocation: action.marker,
      });

    case DialogActionType.SHOW_LEAVE_CHANNEL:
      return Object.assign({}, state, {
        showLeaveChannel: action.show,
      });

    case DialogActionType.SHOW_CREATE_CHANNEL:
      return Object.assign({}, state, {
        showCreateChannel: action.show,
      });
    case DialogActionType.SHOW_ACCOUNT:
      return Object.assign({}, state, {
        showAccount: action.show,
      });
    case DialogActionType.SHOW_LOYALTY:
      return Object.assign({}, state, {
        showLoyalty: action.show,
      });
    case DialogActionType.SHOW_CAMERA:
      return Object.assign({}, state, {
        showCamera: action.show,
      });
    default:
      return state;
  }
}

function channel(state = initialChannelState, action: ChannelAction) {
  switch (action.type) {
    case ChannelActionType.SET_CHANNEL:
      return Object.assign({}, state, {
        channel: action.channel,
        content: [],
      });
    case ChannelActionType.SET_CHANNELS:
      return Object.assign({}, state, {
        channels: action.channels,
      });
    case ChannelActionType.SET_CHANNEL_CONTENT:
      return Object.assign({}, state, {
        content: action.content,
      });
    case ChannelActionType.SET_CHANNEL_LOCATIONS:
      return Object.assign({}, state, {
        locations: action.locations,
      });
    default:
      return state;
  }
}

function map(state = initalMapState, action: MapAction) {
  switch (action.type) {
    case MapActionType.PAN_TO_IMAGE_MARKER:
      return Object.assign({}, state, {
        panToContentLocation: action.marker ? action.marker : undefined,
      });
    case MapActionType.ZOOM_TO_IMAGE_MARKER:
      let newState = Object.assign({}, state, {
        zoomToContentLocation: action.marker ? action.marker : undefined,
      });
      if (action.zoom) {
        newState = Object.assign({}, newState, {
          zoom: action.zoom,
        });
      }
      return newState;

    case MapActionType.SET_SEARCH_RADIUS:
      return { ...state, searchRadius: action.searchRadius };
    case MapActionType.SET_SEARCH_LOCATION:
      return { ...state, searchLocation: action.searchLocation };
    case MapActionType.SET_SEARCH_PLACE:
      return { ...state, searchPlace: action.searchPlace };
    default:
      return state;
  }
}

function list(state = initalListState, action: ListAction) {
  switch (action.type) {
    case ListActionType.SCROLL_TO_IMAGE_MARKER:
      return Object.assign({}, state, {
        scrollToContentLocation: action.marker,
      });
    case ListActionType.SHOW_CONTENT_LIST:
      return Object.assign({}, state, {
        show: action.show,
      });
    default:
      return state;
  }
}

function location(state = initialLocationState, action: LocationAction) {
  switch (action.type) {
    case LocationActionType.ENABLE_LOCATION_ACCESS:
      return Object.assign({}, state, {
        locationServicesEnabled: action.enabled,
      });
    case LocationActionType.SET_CURRENT_LOCATION:
      return Object.assign({}, state, {
        coordinates: action.coordinates,
      });
      case LocationActionType.SHOW_LOCATION_BANNER:
      return Object.assign({}, state, {
        showLocationBanner: action.show,
      });
    default:
      return state;
  }
}

function auth(state = initialAuthState, action: AuthAction) {
  switch (action.type) {
    case AuthActionType.SET_USER:
      return { ...state, user: action.user };
    default:
      return state;
  }
}

function settings(state = initialSettingsState, action: SettingsAction) {
  switch (action.type) {
    case SettingsActionType.SET_MAP_CONTENT_FILTER:
      return { ...state, mapContentFilter: action.mapContentFilter };
    case SettingsActionType.SET_SELECTED_TAB:
      return { ...state, selectedTab: action.selectedTab };
    default:
      return state;
  }
}

export const hlMapApp = combineReducers({
  map,
  list,
  dialog,
  location,
  auth,
  channel,
  settings,
});
