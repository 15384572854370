import { HLFirebase } from "./HLFirebase";
import { UserSearchResult, UserRefData, UserRef, User } from "./../models/User";
import {
  Channel,
  ChannelRef,
  ChannelRefData,
  ChannelSearchResult
} from "./../models/Channel";
import { store } from "./../redux/store";
import { setChannels, setChannel, setChannelContent } from "./../redux/actions";
import { apiCalls } from "./URLService";
import { Content, ContentData } from "../models/Content";
import "firebase/auth";

interface ChannelServiceProps {
  user: User;
}

class _ChannelService {
  userChannelSubscription?: () => void;

  baseCol = HLFirebase.app.firestore().collection('channels');

  async subscribeUserChannels(user: User) {
    this.userChannelSubscription?.();
    store.dispatch(setChannels([]));
    store.dispatch(setChannel(undefined));
    const userChannelsRef = HLFirebase.app.firestore().collection(
      `users/${user.ref ? user.ref.id : "anonymous"}/channels`
    );

    this.userChannelSubscription = userChannelsRef.onSnapshot(
      async result => {
        const channelRefs = result.docs.map(snap => {
          return {
            ref: snap.ref,
            data: snap.data() as ChannelRefData
          } as ChannelRef
        })
        store.dispatch(setChannels(channelRefs));
        if (!store.getState().channel.channel) {
          store.dispatch(setChannel(channelRefs[0]));
        }
      },
      e => console.log(e)
    );
  }

  async joinChannel(channel: Channel | ChannelSearchResult) {
    const user = store.getState().auth.user;
    if (!user) {
      return;
    }
    const uid = user.firebaseAuth.uid;
    const channelId = "ref" in channel ? channel.ref?.id : channel.id;
    await this.baseCol.doc(channelId).collection('members').doc(uid).set(
      {
        name: user.data?.displayName || uid,
        ref: user.ref,
        roles: []
      });
  }

  async getChannelMembers(channel: Channel) {
    const channelQuery = await HLFirebase.app.firestore().collection(
      `channels/${channel.ref.id}/members`
    ).get();
    return channelQuery.docs.map(d => {
      return {
        ref: d.ref,
        data: d.data() as UserRefData,
      } as UserRef
    });
  }

  async createChannel(
    channelName: string,
    visibility: "public" | "private" | "hidden" | string
  ) {
    await apiCalls.post.createChannel(channelName, visibility);
  }

  async leaveChannel(channel: Channel | ChannelSearchResult) {
    const user = store.getState().auth.user;
    if (!user) {
      return;
    }
    const channelId = "ref" in channel ? channel.ref?.id : channel.id;
    await HLFirebase.app.firestore().collection(
      `channels/${channelId}/members`
    ).doc(user.firebaseAuth.uid).delete();
  }

  async getChannel(id: string) {
    return await this.baseCol.doc(id).get();
  }

  async getChannelRef(id: string, uid: string) {
    return await HLFirebase.app.firestore().collection(`users/${uid}/channels`).doc(id).get().then(d => {
      return {
        ref: d.ref,
        data: d.data() as ChannelRefData
      } as ChannelRef
    });
  }

  async addMember(channel: Channel, member: UserSearchResult) {
    await HLFirebase.app.firestore().collection(`channels/${channel.ref.id}/members/`).doc(member.id).set({
      name: member.id,
      ref: `/users/${member.id}`,
      roles: []
    });
  }

  async deleteMember(channel: Channel, member: UserRefData) {
    await HLFirebase.app.firestore().collection(`channels/${channel.ref.id}/members/`).doc(member.ref.id).delete();
  }

  async fetchContent(
    channelId: string,
    count: number = 20,
    lastContent?: Content
  ) {
    const contentCollection = HLFirebase.app.firestore().collection(
      `/channels/${channelId}/content`
    );
    // console.log("last content", lastContent);
    const results = await contentCollection.orderBy('createdAt', 'desc').startAfter(lastContent).limit(count > 0 ? count : 1).get();
    const existingContent = store.getState().channel.content;
    const newContent = results.docs.filter(
      content => !existingContent.some(ex => ex.ref.id === content.ref.id)
    ).map(c => {
      return {
        ref: c.ref,
        data: c.data() as ContentData
      } as Content
    });
    // const result = [...existingContent, ...newContent].sort((a, b) => {
    //   return a.data.createdAt > b.data.createdAt
    //     ? 1
    //     : b.data.createdAt > a.data.createdAt
    //     ? -1
    //     : 0;
    // });
    const result = existingContent.concat(newContent);
    // console.log("Fetched new content", newContent);
    store.dispatch(setChannelContent(result));
  }

  subscribeContent(channelId: string, count = 1) {
    return HLFirebase.app.firestore().collection(
      `/channels/${channelId}/content`
    ).orderBy('createdAt', 'desc').limit(count).onSnapshot(r => {
      const results = r.docs.map(d => {
        return {
          ref: d.ref,
          data: d.data() as ContentData
        } as Content
      });
      store.dispatch(setChannelContent(results));
    });
  }

  reactToContent(reaction: string, content: Content) {
    const channelId = content.ref.parent.parent?.id;
    if (channelId) {
      apiCalls.post.reaction(reaction, channelId, content.ref.id);
    }
  }

  async getContent(ref: firebase.firestore.DocumentReference) {
    return ref.get().then(d => {
      return {
        ref: d.ref,
        data: d.data() as ContentData
      } as Content
    });
  }

  async deleteContent(content: Content) {
    const userContentRef = HLFirebase.app.firestore().collection(
      `users/${content.data.creator.ref.id}/content`
    ).doc(content.ref.id);
    await userContentRef.delete();
  }
}

export const ChannelService = new _ChannelService();
