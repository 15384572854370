import React from "react";
import Button from "@material-ui/core/Button";
import { UserService } from "../services/UserService";
import Logo from "../assets/chick.svg";
import { IconButton, TextField } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { Formik } from "formik";
import styles from "./UserLogin.module.css";

export function UserLogin(props: { onClose?: () => void; onBack: () => void }) {
  const [formErrors, setFormErrors] = React.useState<{
    general?: string;
    email?: string;
    password?: string;
  }>({ general: undefined, email: undefined, password: undefined });

  const onLogin = async (email: string, password: string) => {
    setFormErrors({ general: undefined });
    await UserService.loginUser(email, password)
      .then(() => {
        props.onClose?.();
      })
      .catch((e?: { code: string; message: string }) => {
        switch (e ? e.code : "") {
          case "auth/user-disabled":
            setFormErrors({
              ...formErrors,
              general: e?.message || "User account has been disabled",
            });
            break;
          case "auth/invalid-email":
            setFormErrors({
              ...formErrors,
              general: e?.message || "Invalid email address",
            });
            break;
          case "auth/user-not-found":
            setFormErrors({
              ...formErrors,
              general: e?.message || "Email address not registered",
            });
            break;
          case "auth/wrong-password":
            setFormErrors({
              ...formErrors,
              general: e?.message || "Password is wrong",
            });
            break;
          default:
            setFormErrors({
              ...formErrors,
              general: e?.message || "System error. Please try again later.",
            });
            break;
        }
      });
  };

  return (
    <div className={styles.outerContainer}>
      {props.onClose ? (
        <div style={{ position: "absolute", right: 0, top: 0 }}>
          <IconButton aria-label="close" onClick={props.onClose}>
            <Close />
          </IconButton>
        </div>
      ) : null}

      <div className={styles.innerContainer}>
        <img className={styles.logo} alt="logo" src={Logo} />
        <h2>Login to spread hard love</h2>
        <Formik
          initialValues={{ email: "", password: "" }}
          validate={(values) => {
            const errors: any = {};
            if (!values.email) {
              errors.email = "Required";
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = "Invalid email address";
            }

            if (!values.password) {
              errors.password = "Required";
            } else if (values.password.length < 8) {
              errors.password = "Minimum password length is 8";
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            onLogin(values.email, values.password).then(() =>
              setSubmitting(false)
            );
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit} className={styles.loginForm}>
              <TextField
                color="secondary"
                className="textfield"
                error={!!errors.email && !!touched.email}
                helperText={errors.email}
                required
                fullWidth
                name="email"
                type="email"
                label="Email"
                placeholder="hard@love.com"
                variant="outlined"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <TextField
                color="secondary"
                className="textfield"
                fullWidth
                error={!!errors.password && !!touched.password}
                helperText={errors.password}
                required
                name="password"
                type="password"
                label="Password"
                placeholder="********"
                variant="outlined"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <div
                style={{ color: "gray", fontSize: 10, alignSelf: "flex-end" }}
              >
                * Required fields
              </div>
              <div
                style={{ color: "red", fontSize: 12 }}
                hidden={!formErrors.general}
              >
                {formErrors.general}
              </div>
              <div className={styles.buttonContainer}>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={() => {
                    props.onBack();
                  }}
                >
                  Go Back
                </Button>
                <Button
                  disabled={isSubmitting}
                  variant="contained"
                  color="primary"
                  fullWidth
                  type="submit"
                >
                  Login
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
}
