import React, { ReactNode } from "react";
import { DragImage } from "../components/DragImage";
import { LayerRefProps } from "../components/DynamicContentLayers";

export const HarteLiebeLayer = React.forwardRef<LayerRefProps, {
  children?: ReactNode;
  containerRef?: React.RefObject<HTMLDivElement>;
}>(
  (
    props,
    ref
  ) => {
    const _imageRef = React.useRef<HTMLImageElement>(null);

    const loadImage = (img: HTMLImageElement, src: string) => {
      return new Promise((res, rej) => {
        img.onload = () => {
          res();
        };
        img.onerror = () => {
          rej();
        };
        img.src = src;
      });
    };

    React.useImperativeHandle(ref, () => ({
      merge,
    }));

    const merge = async (
      context: CanvasRenderingContext2D,
      width: number,
      height: number
    ) => {
      const matrix =
        _imageRef.current!.style.webkitTransform ||
        _imageRef.current!.style.transform;
      let angle = 0;
      let scale = 0;
      let x = 0;
      let y = 0;
      if (matrix && matrix !== "none") {
        angle = parseFloat(matrix.split("(")[2].split(")")[0]);
        scale = parseFloat(matrix.split("(")[3].split(")")[0]);
        x = parseFloat(matrix.split("(")[1].split(")")[0].split(",")[0]);
        y = parseFloat(matrix.split("(")[1].split(")")[0].split(",")[1]);
      }

      const scaleX = width / (props.containerRef?.current?.clientWidth || 1);
      const scaleY = height / (props.containerRef?.current?.clientHeight || 1);

      const viewFrame = {
        top: y * scaleY,
        left: x * scaleX,
        size: { height: 222 * scaleY, width: 200 * scaleX },
      };

      const mergedImage = new Image(
        viewFrame.size.width,
        viewFrame.size.height
      );
      await loadImage(mergedImage, _imageRef.current!.src);
      context.save();
      // Move to corner of image
      context.translate(viewFrame.left, viewFrame.top);

      // Move to center of image
      context.translate(
        viewFrame.size.width / 2.0,
        viewFrame.size.height / 2.0
      );
      // Scale and rotate
      context.scale(scale, scale);
      context.rotate((angle * Math.PI) / 180);
      // Move back to corner of image
      context.translate(
        -viewFrame.size.width / 2.0,
        -viewFrame.size.height / 2.0
      );
      // Draw image to context on 0, 0
      context.drawImage(
        mergedImage,
        0,
        0,
        viewFrame.size.width,
        viewFrame.size.height
      );
      context.restore();
    };

    return (
      <DragImage
        x={
          props.containerRef?.current
            ? props.containerRef.current.offsetWidth / 2 - 100
            : 0
        }
        y={
          props.containerRef?.current
            ? props.containerRef.current.offsetHeight / 2 - 100
            : 0
        }
        imgRef={_imageRef}
        containerRef={props.containerRef}
      ></DragImage>
    );
  }
);
