import React from "react";
import { Content, ContentLocation } from "../models/Content";
import { IconButton, Dialog, Chip } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { Data } from "../services/Data";
import { ContentGalleryItem } from "../components/ContentGalleryItem";
import { useSelector } from "react-redux";
import { HlMapAppState } from "../redux/reducers";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ImageGallery, { ReactImageGalleryItem } from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import "./ImageDetail.css";
import { User } from "../models/User";
import { ChannelService } from "../services/ChannelService";
import { ReactComponent as FistEmoji } from "../assets/fist_emoji.svg";
import { ReactComponent as HeartEmoji } from "../assets/heart_emoji.svg";

export const ContentDetail = (props: {
  onClose: (selectedContent?: Content | null) => void;
  open: boolean;
  content: (Content | ContentLocation)[];
  user?: User;
}) => {
  const [
    selectedContent,
    setSelectedContent,
  ] = React.useState<Content | null>();
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const selectedContentLocation = useSelector(
    (state: HlMapAppState) => state.dialog.selectContentLocation
  );

  const isMobile = useMediaQuery(useTheme().breakpoints.down("sm"));

  React.useEffect(() => {
    if (selectedContent) {
      const newIndex = props.content.findIndex(
        (m) => m.ref.id === selectedContent?.ref.id
      );
      if (newIndex !== currentIndex) {
        setCurrentIndex(newIndex);
      }
    }
  }, [selectedContent, props.content]);

  const selectContent = React.useCallback(
    (content: Content | ContentLocation) => {
      return Data.observeContentWithFunction(
        content,
        (content: Content | null) => {
          console.log("got observer call", content?.ref.id);
          if (!content || content?.ref.id === selectedContent?.ref.id) {
            return;
          }
          setSelectedContent(content);
        }
      );
    },
    []
  );

  React.useEffect(() => {
    if (!selectedContent) {
      const sc = props.content.find(
        (m) => m.ref.id === selectedContentLocation?.ref.id
      );
      if (sc) {
        return selectContent(sc);
      } else {
        return selectContent(props.content[currentIndex]);
      }
    }
  }, [selectedContentLocation, props.content, selectContent]);

  React.useEffect(() => {
    if (currentIndex < props.content.length) {
      return selectContent(props.content[currentIndex]);
    }
  }, [currentIndex]);

  const onSlide = async (index: number) => {
    setCurrentIndex(index);
  };

  const onClose = () => {
    props.onClose(selectedContent);
  };

  const dialogStyle = () => {
    if (isMobile) {
      return {};
    }
    return {
      boxShadow: "none",
      minHeight: "90vh",
      minWidth: "90vw",
      maxWidth: "90vw",
      maxHeight: "90vh",
    };
  };

  const getReactionLabelText = React.useCallback(
    (s: string) => {
      const reaction = selectedContent?.data?.reactions?.find(
        (r) => r.reaction === s
      );
      return reaction?.count || 0;
    },
    [selectedContent]
  );

  const addedReaction = React.useCallback(
    (s: string) => {
      return selectedContent?.data?.reactions?.some(
        (r) =>
          r.reaction === s &&
          r.users.some((u) => u === props.user?.firebaseAuth.uid)
      );
    },
    [selectedContent, props.user]
  );

  const onReaction = React.useCallback(
    (emoji: string) => {
      if (selectedContent) {
        ChannelService.reactToContent(emoji, selectedContent);
      }
    },
    [selectedContent]
  );

  return (
    <Dialog
      fullScreen={isMobile}
      PaperProps={{ style: { backgroundColor: "black", ...dialogStyle() } }}
      onClose={onClose}
      open={props.open}
      maxWidth={false}
    >
      <div
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          maxHeight: "100%",
          maxWidth: "100%",
        }}
      >
        <ImageGallery
          startIndex={currentIndex}
          showFullscreenButton={!isMobile}
          lazyLoad={true}
          onSlide={onSlide}
          showThumbnails={false}
          renderCustomControls={() => {
            return (
              <div
                style={{
                  zIndex: 1,
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  lineHeight: "normal",
                  pointerEvents: "none",
                }}
              >
                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    pointerEvents: "all",
                    padding: 8,
                  }}
                >
                  {["hard", "love"].map((e) => {
                    return (
                      <Chip
                        key={e}
                        className="action"
                        icon={e === "hard" ? <FistEmoji width={24} height={24} /> : <HeartEmoji width={24} height={24} />}
                        label={getReactionLabelText(e)}
                        onClick={
                          !addedReaction(e) ? () => onReaction(e) : undefined
                        }
                        onDelete={
                          addedReaction(e) ? () => onReaction(e) : undefined
                        }
                      />
                    );
                  })}
                </div>
              </div>
            );
          }}
          items={props.content.map((m) => {
            return {
              renderItem: (item?: ReactImageGalleryItem) => {
                if (item) {
                  return <ContentGalleryItem key={m.ref.id} content={m} />;
                }
              },
              original: m.ref.id,
            };
          })}
        />
      </div>
      <IconButton
        onClick={onClose}
        style={{
          color: "white",
          position: "absolute",
          top: 0,
          left: 0,
        }}
      >
        <Close />
      </IconButton>
      )
    </Dialog>
  );
};
