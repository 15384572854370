import React, { RefObject, MutableRefObject } from "react";
import Styles from "./DynamicContentLayers.module.css";
import { ConfettiLayer } from "../Layers/ConfettiLayer";
export interface Layer {
  id: string;
  name: string;
  render: (
    containerRef?: RefObject<HTMLDivElement>,
    layerRefs?: MutableRefObject<any[]>
  ) => any;
}

export interface LayerRefProps {
  merge: (
    context: CanvasRenderingContext2D,
    width: number,
    height: number
  ) => Promise<void>;
}

export const availableLayers = [
  {
    id: "confetti",
    name: "🎉",
    render: (
      containerRef?: RefObject<HTMLDivElement>,
      layerRefs?: MutableRefObject<any[]>
    ) => (
      <ConfettiLayer
        key="confetti"
        containerRef={containerRef}
        ref={(ref) =>
          layerRefs ? (layerRefs.current[0] = ref) : null
        }
      />
    ),
  },
];

export const DynamicContentLayers = (props: { layers: string[] }) => {
  return (
    <div className={Styles.outerContainer}>
      {props.layers.map((id) => {
        return availableLayers.find((l) => l.id === id)?.render();
      })}
    </div>
  );
};
