import { ChannelRef } from "./../models/Channel";
import { FilterSetting } from "./../models/Settings";
import { Content, ContentLocation } from "../models/Content";
import { User } from "./../models/User";

export enum AuthActionType {
  SET_USER = "SET_USER",
}

export enum SettingsActionType {
  SET_MAP_CONTENT_FILTER = "SET_MAP_CONTENT_FILTER",
  SET_SELECTED_TAB = "SET_SELECTED_TAB",
}

export enum ChannelActionType {
  SET_CHANNEL = "SET_SELECTED_CHANNEL",
  SET_CHANNELS = "SET_CHANNELS",
  SET_CHANNEL_CONTENT = "SET_CHANNEL_CONTENT",
  SET_CHANNEL_LOCATIONS = "SET_CHANNEL_LOCATIONS",
}

export enum MapActionType {
  PAN_TO_IMAGE_MARKER = "PAN_TO_IMAGE_MARKER",
  ZOOM_TO_IMAGE_MARKER = "ZOOM_TO_IMAGE_MARKER",
  SET_SEARCH_RADIUS = "SET_SEARCH_RADIUS",
  SET_SEARCH_LOCATION = "SET_SEARCH_LOCATION",
  SET_SEARCH_PLACE = "SET_SEARCH_PLACE",
}

export enum ListActionType {
  SCROLL_TO_IMAGE_MARKER = "SCROLL_TO_IMAGE_MARKER",
  SHOW_CONTENT_LIST = "SHOW_CONTENT_LIST",
}

export enum DialogActionType {
  SHOW_LOCATION_DETAIL = "SHOW_LOCATION_DETAIL",
  SHOW_CONTENT_DETAIL = "SHOW_CONTENT_DETAIL",
  SELECT_MAP_MARKER = "SELECT_MAP_MARKER",
  SHOW_LEAVE_CHANNEL = "SHOW_LEAVE_CHANNEL",
  SHOW_CREATE_CHANNEL = "SHOW_CREATE_CHANNEL",
  SHOW_ACCOUNT = "SHOW_ACCOUNT",
  SHOW_CAMERA = "SHOW_CAMERA",
  SHOW_LOYALTY = "SHOW_LOYALTY",
}

export enum LocationActionType {
  ENABLE_LOCATION_ACCESS = "ENABLE_LOCATION_ACCESS",
  SET_CURRENT_LOCATION = "SET_CURRENT_LOCATION",
  SHOW_LOCATION_BANNER = "SHOW_LOCATION_BANNER",
}

export interface AuthAction {
  type: AuthActionType;
  user?: User;
}

export interface SettingsAction {
  type: SettingsActionType;
  mapContentFilter: string[];
  selectedTab: number;
}

export interface ChannelAction {
  type: ChannelActionType;
  channel?: ChannelRef;
  channels?: ChannelRef[];
  content?: Content[];
  locations?: ContentLocation[];
}
export interface LocationAction {
  type: LocationActionType;
  coordinates?: Coordinates;
  enabled?: boolean;
  show?: boolean;
}

export interface ListAction {
  type: ListActionType;
  marker?: ContentLocation;
  show?: boolean;
}

export interface MapAction {
  type: MapActionType;
  marker?: ContentLocation;
  markers?: ContentLocation[];
  zoom?: number;
  searchRadius?: number;
  searchLocation?: Coordinates;
  searchPlace?: google.maps.places.PlaceResult;
}

export interface DialogAction {
  type: DialogActionType;
  marker?: ContentLocation;
  show?: boolean;
}

export function showLocationDetail(show: boolean): DialogAction {
  return {
    type: DialogActionType.SHOW_LOCATION_DETAIL,
    show,
  };
}

export function showContentDetail(show: boolean): DialogAction {
  return {
    type: DialogActionType.SHOW_CONTENT_DETAIL,
    show,
  };
}

export function showLocationBanner(show: boolean): LocationAction {
  return {
    type: LocationActionType.SHOW_LOCATION_BANNER,
    show,
  };
}

export function showAccount(show: boolean): DialogAction {
  return {
    type: DialogActionType.SHOW_ACCOUNT,
    show,
  };
}

export function showLoyalty(show: boolean): DialogAction {
  return {
    type: DialogActionType.SHOW_LOYALTY,
    show,
  };
}

export function showCamera(show: boolean): DialogAction {
  return {
    type: DialogActionType.SHOW_CAMERA,
    show,
  };
}

export function showCreateChannel(show: boolean): DialogAction {
  return {
    type: DialogActionType.SHOW_CREATE_CHANNEL,
    show,
  };
}
export function showLeaveChannel(show: boolean): DialogAction {
  return {
    type: DialogActionType.SHOW_LEAVE_CHANNEL,
    show,
  };
}

export function selectContentLocation(marker?: ContentLocation): DialogAction {
  return {
    type: DialogActionType.SELECT_MAP_MARKER,
    marker,
  };
}

export function panToContentLocation(marker?: ContentLocation): MapAction {
  return {
    type: MapActionType.PAN_TO_IMAGE_MARKER,
    marker,
  };
}

export function scrollToContentLocation(marker?: ContentLocation): ListAction {
  return {
    type: ListActionType.SCROLL_TO_IMAGE_MARKER,
    marker,
  };
}

export function zoomToContentLocation(
  marker?: ContentLocation,
  zoom?: number
): MapAction {
  return {
    type: MapActionType.ZOOM_TO_IMAGE_MARKER,
    marker,
    zoom,
  };
}

export function enableLoctionServices(enabled: boolean): LocationAction {
  return {
    type: LocationActionType.ENABLE_LOCATION_ACCESS,
    enabled,
  };
}

export function setCurrentLocation(coords: Coordinates): LocationAction {
  return {
    type: LocationActionType.SET_CURRENT_LOCATION,
    coordinates: coords,
  };
}

export function showContentList(show: boolean): ListAction {
  return {
    type: ListActionType.SHOW_CONTENT_LIST,
    show,
  };
}

export function setUser(user?: User): AuthAction {
  return {
    type: AuthActionType.SET_USER,
    user: user,
  };
}

export function setSearchRadius(radius?: number): MapAction {
  return {
    type: MapActionType.SET_SEARCH_RADIUS,
    searchRadius: radius,
  };
}

export function setSearchLocation(location?: Coordinates): MapAction {
  return {
    type: MapActionType.SET_SEARCH_LOCATION,
    searchLocation: location,
  };
}

export function setSearchPlace(
  place?: google.maps.places.PlaceResult
): MapAction {
  return {
    type: MapActionType.SET_SEARCH_PLACE,
    searchPlace: place,
  };
}

export function setChannel(channel?: ChannelRef): ChannelAction {
  return {
    type: ChannelActionType.SET_CHANNEL,
    channel,
    content: [],
    locations: [],
  };
}

export function setChannels(channels: ChannelRef[]) {
  return {
    type: ChannelActionType.SET_CHANNELS,
    channels,
  };
}

export function setChannelContent(content: Content[]) {
  return {
    type: ChannelActionType.SET_CHANNEL_CONTENT,
    content,
  };
}

export function setChannelLocations(locations: ContentLocation[]) {
  return {
    type: ChannelActionType.SET_CHANNEL_LOCATIONS,
    locations,
  };
}

export function setMapContentFilter(filterContent: FilterSetting[]) {
  return {
    type: SettingsActionType.SET_MAP_CONTENT_FILTER,
    mapContentFilter: filterContent,
  };
}

export function setSelectedTab(selectedTab: number) {
  return {
    type: SettingsActionType.SET_SELECTED_TAB,
    selectedTab,
  };
}
