import { store } from "./../redux/store";
import { apiCalls } from "./URLService";
import {
  UserData,
  SentFriendRequest,
  ReceivedFriendRequest
} from "./../models/User";
import { HLFirebase } from "./HLFirebase";
import "firebase/auth";
import { setUser } from "../redux/actions";
import Cookies from "js-cookie";
import { ChannelService } from './ChannelService';
const users = HLFirebase.app.firestore().collection("users");

class _UserService {

  userSubscription?: () => void;

  subscribeToAuth() {
    HLFirebase.app.auth().onAuthStateChanged(async authUser => {
      this.parseAuthUser(authUser);
    });
  }

  async parseAuthUser(authUser: firebase.User | null) {
    this.userSubscription?.();
    if (authUser) {
      const idToken = await authUser.getIdToken();
      const csrfToken = Cookies.get("csrfToken");
      await apiCalls.post.sessionLogin(idToken, csrfToken);
      if (authUser.isAnonymous) {
        const user = { firebaseAuth: authUser };
        store.dispatch(setUser(user));
        ChannelService.subscribeUserChannels(user);
      } else {
        this.userSubscription = users.doc(authUser.uid).onSnapshot(userDoc => {
          const user = { firebaseAuth: authUser, data: userDoc.data() as UserData, ref: userDoc.ref };
          store.dispatch(setUser(user));
          ChannelService.subscribeUserChannels(user);
        });
        return this.userSubscription;
      }
    } else {
      HLFirebase.app.auth().signInAnonymously();
    }
  }

  getAuthToken = async () => {
    if (store.getState().auth.user) {
      return await store.getState().auth.user!.firebaseAuth!.getIdToken();
    }
  };

  registerUser = async (displayName: string, email: string, password: string) => {
    const response = await apiCalls.post.register(displayName, email, password);
    const result = await response.json() as { token: string };
    return await (await HLFirebase.app.auth().signInWithCustomToken(result.token)).user;
  };

  loginUser = async (email: string, password: string) => {
    return HLFirebase.app.auth().signInWithEmailAndPassword(email, password);
  };

  logout = async () => {
    await apiCalls.post.sessionLogout();
    return HLFirebase.app.auth().signOut();
  };

  // addFriend = async (uid: string) => {
  //   const friend = await get(users, uid);
  //   if (friend && store.getState().auth.user) {
  //     const user = await get(
  //       users,
  //       store.getState().auth.user!.firebaseAuth.uid
  //     );
  //     if (!user) {
  //       return;
  //     }
  //     const friends = user.data.friends || [];
  //     friends.push(friend.ref);
  //     await update(users, user.ref.id, { friends });
  //     return friends;
  //   }
  // };

  // removeFriend = async (uid: string) => {
  //   const friend = await get(users, uid);
  //   if (friend && store.getState().auth.user) {
  //     const user = await get(
  //       users,
  //       store.getState().auth.user!.firebaseAuth.uid
  //     );
  //     if (!user) {
  //       return;
  //     }
  //     const friends = user.data.friends
  //       ? user.data.friends.filter(ref => ref.id !== friend.ref.id)
  //       : [];
  //     await update(users, user.ref.id, { friends });
  //     return friends;
  //   }
  // };

  // getFriends = async () => {
  //   if (!store.getState().auth.user) {
  //     return [];
  //   }
  //   const friendsCol = collection<FriendData>(
  //     `/users/${store.getState().auth.user!.firebaseAuth.uid}/friends`
  //   );
  //   return await all(friendsCol);
  // };

  requestFriendship = async (code: string) => {
    await apiCalls.put.friendRequest(code);
  };

  abortSentFriendRequests = async (requests: SentFriendRequest[]) => {
    await apiCalls.delete.sentFriendRequests(requests);
  };

  declineReceivedFriendRequests = async (requests: ReceivedFriendRequest[]) => {
    await apiCalls.put.declineReceivedFriendRequests(requests);
  };

  acceptReceivedFriendRequests = async (requests: ReceivedFriendRequest[]) => {
    await apiCalls.put.acceptReceivedFriendRequests(requests);
  };

  uploadAvatar = async (file: any) => {
    var data = new FormData();
    data.append("avatar", file);
    if (file.size > 1000000) {
      alert("file to large!");
      return;
    }
    return apiCalls.post.avatar(data);
  };
}

export const UserService = new _UserService();
