import React from "react";
import { User } from "./../models/User";
import { Button, CircularProgress } from "@material-ui/core";
import { UserService } from "../services/UserService";
import useStyles from "./UserProfileStyles";
import { apiUrls } from "../services/URLService";
import AlertDialog from "../dialogs/AlertDialog";
import Chick from "../assets/chick.svg";

export function UserProfile(props: { selected: boolean; user: User }) {
  const [showLogoutAlert, setShowLogoutAlert] = React.useState(false);
  const [error, setError] = React.useState("");
  const [uploadError, setUploadError] = React.useState("");
  const [isAvatarLoading, setIsAvatarLoading] = React.useState(false);
  const fileRef = React.useRef<HTMLInputElement>(null);
  const onLogout = () => {
    return UserService.logout();
  };

  const editAvatar = () => {
    if (fileRef && fileRef.current) {
      fileRef!.current!.click();
    }
  };

  const onChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event && event.target.files?.length) {
      const file = event.target.files[0];
      setIsAvatarLoading(true);
      UserService.uploadAvatar(file)
        .then(val => {
          if (!val || !val.ok) {
            setUploadError(
              "The minimum image size is 256x256 and maximum file size is 10MB."
            );
          } else {
            setUploadError("");
          }
        })
        .catch(e => setUploadError(e))
        .finally(() => setIsAvatarLoading(false));
    }
  };

  const styles = useStyles();

  return (
    <>
      <div
        className={styles.outerContainer}
        style={{ display: props.selected ? "flex" : "none" }}
      >
        <div className={styles.userInformation}>
          <div className={styles.userAvatar} onClick={() => editAvatar()}>
            {isAvatarLoading ? (
              <div className={styles.userAvatarLoading}>
                <CircularProgress />
              </div>
            ) : (
                <>
                  <img
                    alt="avatar"
                    className={styles.userAvatarImage}
                    placeholder={Chick}
                    src={
                      props.user.ref
                        ? apiUrls.get.avatar(props.user.ref.id) + '?' + Date.now()
                        : Chick
                    }
                  />
                  <div className={styles.error}>{uploadError}</div>
                </>
              )}

            <input
              style={{ display: "none" }}
              name="avatar"
              type="file"
              accept="image/png, image/jpeg"
              ref={fileRef}
              onChange={onChangeFile}
            />
          </div>
          <div className={styles.userDetails}>
            <label>Username</label>
            <div className={styles.userName}>
              {props.user.data?.displayName}
            </div>
            <label>Email</label>
            <div className={styles.userEmail}>{props.user.data?.email}</div>
            <label>Joined on</label>
            <div className={styles.userJoined}>
              {props.user.data?.createdAt.toDate().toLocaleDateString()}
            </div>
          </div>
        </div>
        {/* <div className={styles.statistics}>
          <div className={styles.channelStats}>
            You've joined {props.user.doc?.data.statistics.channels} meaningful
            channels
          </div>
          <div className={styles.contentStats}>
            You've provided {props.user.doc?.data.statistics.content} elements
            of high value as content
          </div>
        </div> */}
        <div style={{ flex: 1 }} />
        <Button variant="contained" onClick={() => setShowLogoutAlert(true)}>
          Logout
        </Button>
      </div>
      <AlertDialog
        title="Logout"
        description="Do you really want to log out?"
        buttonYesTitle="Log me out!"
        buttonNoTitle="Errm, no cancel that!"
        error={error}
        open={showLogoutAlert}
        onClose={() => {
          setShowLogoutAlert(false);
        }}
        onAnswerSelected={answer => {
          if (answer) {
            onLogout()
              .then(() => {
                setShowLogoutAlert(false);
              })
              .catch(e => setError(e));
          } else {
            setShowLogoutAlert(false);
          }
        }}
      />
    </>
  );
}
