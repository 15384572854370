import React from "react";
import { apiUrls } from "../services/URLService";
import { Content } from "../models/Content";
import { LocationOn, ZoomIn, ZoomOut, DeleteForever } from "@material-ui/icons";
import { IconButton, CircularProgress, Chip } from "@material-ui/core";
import "./ContentElement.css";
import { User } from "../models/User";
import { ReactComponent as FistEmoji } from "../assets/fist_emoji.svg";
import { ReactComponent as HeartEmoji } from "../assets/heart_emoji.svg";

interface ContentElementProps {
  onSelect: (content: Content) => void;
  onZoomIn: (content: Content) => void;
  onZoomOut: (content: Content) => void;
  onCenter: (content: Content) => void;
  onDelete: (content: Content) => void;
  onReaction: (emoji: string, content: Content) => void;
  onShowPicker: (event: any, content: Content) => void;
  onLoad?: () => void;
  ref?: any;
  style?: any;
  content: Content;
  user?: User;
}

export const ContentElement: React.FC<ContentElementProps> = (props) => {
  const [imageUrl, setImageUrl] = React.useState<string>();
  const [imageLoaded, setImageLoaded] = React.useState(false);

  const getReactionLabelText = (s: string) => {
    const reaction = props.content.data.reactions.find((r) => r.reaction === s);
    return reaction?.count || 0;
  };

  const addedReaction = (s: string) => {
    return props.content.data.reactions.some(
      (r) =>
        r.reaction === s &&
        r.users.some((u) => u === props.user?.firebaseAuth.uid)
    );
  };

  React.useEffect(() => {
    if (!imageUrl && props.content.data.image) {
      setImageUrl(apiUrls.get.image(props.content.data.image.id, 600));
    } else if (!props.content.data.image) {
      props.onLoad?.();
    }
  }, [props.content.ref.id]);
  return (
    <div ref={props.ref} style={props.style} className="outerContainer">
      <div className="innerContainer">
        {/* <img
          className="avatar"
          alt="avatar"
          src={apiUrls.get.avatar(content.data.creator.ref.id)}
        /> */}
        <div className="contentContainer">
          {/* <div className="header">
            <div className="creator">{content.data.creator.name}</div>
            <div className="createdDate">
              {content.data.createdAt.toLocaleString()}
            </div>
          </div> */}
          <div
            className="content"
            onClick={() => props.onSelect(props.content)}
          >
            {props.content.data.text && (
              <div className="text">{props.content.data.text}</div>
            )}
            {props.content.data.image && (
              <div className="imageContainer">
                <img
                  className="image"
                  id="image"
                  alt={props.content.ref.id}
                  onLoad={() => {
                    setImageLoaded(true);
                    props.onLoad?.();
                  }}
                  src={imageUrl}
                />
                {!imageLoaded && (
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      height: "100%",
                      width: "100%",
                      flex: 1,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress />
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="footer">
            <div className="actions">
              {["hard", "love"].map((e) => {
                return (
                  <Chip
                    key={e}
                    className="action"
                    icon={
                      e === "hard" ? (
                        <FistEmoji width={24} height={24} />
                      ) : (
                        <HeartEmoji width={24} height={24} />
                      )
                    }
                    label={getReactionLabelText(e)}
                    onClick={
                      !addedReaction(e)
                        ? () => props.onReaction(e, props.content)
                        : undefined
                    }
                    onDelete={
                      addedReaction(e)
                        ? () => props.onReaction(e, props.content)
                        : undefined
                    }
                  />
                );
              })}
              <div style={{ flex: 1 }} />
              <IconButton
                size="small"
                onClick={() => props.onCenter(props.content)}
              >
                <LocationOn />
              </IconButton>
              <IconButton
                size="small"
                onClick={() => props.onZoomOut(props.content)}
              >
                <ZoomOut />
              </IconButton>
              <IconButton
                size="small"
                onClick={() => props.onZoomIn(props.content)}
              >
                <ZoomIn />
              </IconButton>

              {(props.content.data.creator.ref.id ===
                props.user?.firebaseAuth.uid ||
                props.user?.data?.roles?.includes("canDeleteContent")) && (
                <IconButton
                  size="small"
                  onClick={() => props.onDelete(props.content)}
                >
                  <DeleteForever />
                </IconButton>
              )}
              {/* <IconButton
                size="small"
                onClick={(event) => {
                  props.onShowPicker(event, props.content);
                }}
              >
                <InsertEmoticon />
              </IconButton> */}
            </div>
            {/* <div className="emojis">
              {props.content.data.reactions.map((r) => {
                return (
                  <IconButton
                    key={r.reaction}
                    size="small"
                    onClick={() => props.onReaction(r.reaction, props.content)}
                  >
                    {r.reaction}
                  </IconButton>
                );
              })}
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};
