import React from "react";
import Button from "@material-ui/core/Button";
import Logo from "../assets/chick.svg";
import {
  IconButton,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import Emoji from "../misc/Emoji";
import styles from "./AnonymousTeaser.module.css";
export function AnonymousTeaser(props: {
  onSignUp: () => void;
  onLogin: () => void;
  onClose?: () => void;
}) {
  return (
    <div className={styles.outerContainer}>
      {props.onClose ? (
        <div className={styles.closeButton}>
          <IconButton aria-label="close" onClick={props.onClose}>
            <Close />
          </IconButton>
        </div>
      ) : null}
      <div className={styles.innerContainer}>
        <img className={styles.logo} alt="logo" src={Logo} />
        <h2 className={styles.title}>Sign up now and get free access</h2>
        <div className={styles.features}>
          <table>
            <tbody>
              <tr>
                <td>
                  <Emoji symbol="📸" />
                </td>
                <td>Add your own pics</td>
              </tr>
              <tr>
                <td>
                  <Emoji symbol="❤️" />
                </td>
                <td>Receive love from your friends</td>
              </tr>
              <tr>
                <td>
                  <Emoji symbol="🗺" />
                </td>
                <td>Search the world for hard love</td>
              </tr>
              <tr>
                <td>
                  <Emoji symbol="🎉" />
                </td>
                <td>Put confetti as a topping</td>
              </tr>
              <tr>
                <td>
                  <Emoji symbol="👊" />
                </td>
                <td>Party hard and let everyone know</td>
              </tr>
              <tr>
                <td>
                  <Emoji symbol="💩" />
                </td>
                <td>Unbelievable hot tight shit</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <Button variant="contained" fullWidth onClick={props.onLogin}>
          Login
        </Button>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={props.onSignUp}
        >
          Sign up
        </Button>
      </div>
    </div>
  );
}
