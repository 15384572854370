import React from "react";
import { AnonymousTeaser } from "../components/AnonymousTeaser";
import { UserDetail } from "../components/UserDetail";
import { UserRegister } from "../components/UserRegister";
import { UserLogin } from "../components/UserLogin";
import { User } from "../models/User";
import { connect } from "react-redux";
import { HlMapAppState } from "../redux/reducers";
import { setSelectedTab, showAccount } from "../redux/actions";
const mapStateToProps = (state: HlMapAppState) => {
  return {
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onSelectTab: (index: number) => dispatch(setSelectedTab(index)),
    onShowDialog: (show: boolean) => dispatch(showAccount(show)),
  };
};

export const Account = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  (props: {
    user?: User;
    style?: React.CSSProperties;
    onSelectTab: (index: number) => void;
    onShowDialog: (show: boolean) => void;
  }) => {
    const [state, setState] = React.useState({ signUp: false, login: false });

    const toggleSignUp = () => {
      setState({ ...state, signUp: !state.signUp });
    };

    const toggleLogin = () => {
      setState({ ...state, login: !state.login });
    };

    const showTeaser = () => {
      return !props.user?.data && !state.signUp && !state.login;
    };

    const showUserLogin = () => {
      return state.login && !props.user?.data;
    };

    const showUserRegistration = () => {
      return state.signUp && !props.user?.data;
    };

    const showUserDetail = () => {
      return props.user?.data;
    };

    const onRegisterBack = () => {
      setState({ ...state, signUp: false });
    };

    const onLoginBack = () => {
      setState({ ...state, login: false });
    };

    return (
      <div style={{ flex: 1, display: "flex", ...props.style }}>
        {showTeaser() && (
          <AnonymousTeaser
            onSignUp={toggleSignUp}
            onLogin={toggleLogin}
          ></AnonymousTeaser>
        )}
        {showUserLogin() && (
          <UserLogin
            onClose={() => {
              props.onShowDialog(false);
              props.onSelectTab(1);
            }}
            onBack={onLoginBack}
          ></UserLogin>
        )}
        {showUserRegistration() && (
          <UserRegister onBack={onRegisterBack}></UserRegister>
        )}
        {showUserDetail() && <UserDetail user={props.user!}></UserDetail>}
      </div>
    );
  }
);
