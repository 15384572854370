import { makeStyles } from "@material-ui/core/styles";
export default makeStyles({
  outerContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "column"
  },
  userInformation: {
    padding: 8,
    flex: 1,
    display: "flex",
    flexDirection: "row",
    position: "relative"
  },
  userAvatar: {
    width: 128,
    minHeight: 128,
    display: "flex",
    flexDirection: "column",
    cursor: 'copy'
  },
  userAvatarImage: {
    height: 128,
    width: 128,
    objectFit: "cover",
    borderRadius: 8
  },
  error: {
    color: "red",
    fontSize: "small"
  },
  userAvatarLoading: {
    position: "absolute",
    width: 128,
    height: 128,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    top: 4,
    left: 4
  },
  userDetails: {
    "& label": {
      color: "gray",
      display: "block",
      fontSize: 12,
      fontStyle: "italic",
      marginTop: 8
    },
    "& label:first-child": {
      marginTop: 4
    },
    flex: 1,
    display: "flex",
    flexDirection: "column",
    marginLeft: 8
  },
  userName: {
    fontSize: 24
  },
  userEmail: {
    fontStyle: "italic"
  },
  userJoined: {},
  statistics: {
    flex: 1,
    display: "flex",
    flexDirection: "column"
  },
  channelStats: {},
  contentStats: {}
});
